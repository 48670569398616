import { all, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster } from '../../../utils/helper'
import { GET_CLINIC_FILTER_LIST_REQUEST } from '../../actions/actionTypes'
import { getClinicFilterListRequestFailure, getClinicFilterListRequestSuccess } from '../../actions/commonFilter/clinicFilterAction'

// get clinic for the filter list
function* getClinicFilterListRequestSaga(): any {
  try {
    const response = yield API.get(`v1/clinic/details/filter`)
    yield put(
      getClinicFilterListRequestSuccess({
        clinicFilterData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      getClinicFilterListRequestFailure({
        clinicFilterError: e?.meta?.message,
      })
    )
  }
}

function* getClinicFilterList() {
  yield takeEvery(GET_CLINIC_FILTER_LIST_REQUEST, getClinicFilterListRequestSaga)
}

export default function* ClinicFilterSaga() {
  yield all([getClinicFilterList()])
}
