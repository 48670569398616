import {
  SEND_EMAIL_VERIFICATION_REQUEST,
  SEND_EMAIL_VERIFICATION_REQUEST_FAILURE,
  SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS,
  VERIFIED_EMAIL_REQUEST,
  VERIFIED_EMAIL_REQUEST_FAILURE,
  VERIFIED_EMAIL_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { verifiedEmailActions, VerifiedEmailInterface } from '../../schema/auth/verifiedEmailSchema'

const initialState: VerifiedEmailInterface = {
  verifiedEmailLoader: false,
  verifiedEmailError: '',
  sendEmailVerificationLoader: false,
  sendEmailVerificationError: '',
}
const verifiedEmailReducer = (state = initialState, action: verifiedEmailActions) => {
  switch (action.type) {
    case VERIFIED_EMAIL_REQUEST:
      return {
        ...state,
        verifiedEmailLoader: true,
      }
    case VERIFIED_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        verifiedEmailLoader: false,
        verifiedEmailError: null,
      }
    case VERIFIED_EMAIL_REQUEST_FAILURE:
      return {
        ...state,
        verifiedEmailLoader: false,
        verifiedEmailError: action.payload.verifiedEmailError,
      }

    case SEND_EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        sendEmailVerificationLoader: true,
      }
    case SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        sendEmailVerificationLoader: false,
        sendEmailVerificationError: null,
      }
    case SEND_EMAIL_VERIFICATION_REQUEST_FAILURE:
      return {
        ...state,
        sendEmailVerificationLoader: false,
        sendEmailVerificationError: action.payload.sendEmailVerificationError,
      }
    default:
      return {
        ...state,
      }
  }
}
export default verifiedEmailReducer
