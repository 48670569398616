import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import { MATCH_PASSWORD_REQUEST } from '../../actions/actionTypes'
import { matchPasswordRequestFailure, matchPasswordRequestSuccess } from '../../actions/authActions/matchPasswordAction'

function* matchPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v1/auth/account/password`, payload.payload)
    yield call(payload?.payload?.callback, response?.data)
    successToaster(response?.data?.meta?.message)
    yield put(
      matchPasswordRequestSuccess({
        matchPasswordData: response.data,
      })
    )
  } catch (e: any) {
    // TODO: update toast according to status code
    errorToaster(e?.meta?.message)
    yield put(
      matchPasswordRequestFailure({
        matchPasswordError: e?.meta?.message,
      })
    )
  }
}

function* postMatchPasswordSaga() {
  yield takeEvery(MATCH_PASSWORD_REQUEST, matchPasswordRequestSaga)
}

export default function* matchPasswordSaga() {
  yield all([postMatchPasswordSaga()])
}
