import { combineReducers } from 'redux'
import { appointmentLocalReducer } from './appointment/appointmentLocalReducer'
import appointmentReducer from './appointment/appointmentReducer'
import approveClinicReducer from './approveClinic/approveClinicReducer'
import clinicAdminChangePasswordReducer from './auth/changePasswordReducer'
import communicationReducer from './auth/communicationsReducer'
import createPasswordReducer from './auth/createPasswordReducer'
import clinicAdminForgotPasswordReducer from './auth/forgotPasswordReducer'
import clinicAdminLoginReducer from './auth/loginReducer'
import matchPasswordReducer from './auth/matchPasswordReducer'
import otpReducer from './auth/otpReducer'
import clinicAdminResetPasswordReducer from './auth/resetPasswordReducer'
import clinicAdminSignUpReducer from './auth/signUpReducer'
import termsConditionPrivacyPolicyReducer from './auth/termsConditionPrivacyPolicyReducer'
import verifiedEmailReducer from './auth/verifiedEmailReducer'
import clinicReducer from './clinic/clinicReducer'
import clinicSettingsReducer from './clinic/clinicSettingsReducer'
import clinicFilterReducer from './commonFilter/clinicFilterReducer'
import verifyUserEmailReducer from './commonFilter/verifyUserEmailReducer'
import assignRoleReducer from './member/assignRoleReducer'
import memberReducer from './member/memberReducer'
import moduleReducer from './module/moduleReducer'
import notificationReducer from './notification/notificationReducer'
import ownerReducer from './owner/ownerReducer'
import ownerProfileReducer from './ownerProfile/ownerProfileReducer'
import patientReducer from './patient/patientReducer'
import preIntakeReducer from './preIntkae/preIntakeReducer'
import subscriptionPlansReducer from './subscriptionPlans/subscriptionPlansReducer'

const rootReducer = combineReducers({
  clinicAdminSignUp: clinicAdminSignUpReducer,
  otpReducer,
  clinicAdminLogin: clinicAdminLoginReducer,
  clinicAdminChangePassword: clinicAdminChangePasswordReducer,
  ownerProfileInfo: ownerProfileReducer,
  clinicAdminForgotPassword: clinicAdminForgotPasswordReducer,
  clinicAdminResetPassword: clinicAdminResetPasswordReducer,
  verifiedEmail: verifiedEmailReducer,
  termsConditionPrivacyPolicy: termsConditionPrivacyPolicyReducer,
  createPassword: createPasswordReducer,
  clinic: clinicReducer,
  member: memberReducer,
  appointment: appointmentReducer,
  patient: patientReducer,
  assignRole: assignRoleReducer,
  module: moduleReducer,
  appointmentLocal: appointmentLocalReducer,
  matchPassword: matchPasswordReducer,
  clinicFilter: clinicFilterReducer,
  approveClinic: approveClinicReducer,
  subscriptionPlans: subscriptionPlansReducer,
  notification: notificationReducer,
  owner: ownerReducer,
  verifyUserEmail: verifyUserEmailReducer,
  preIntake: preIntakeReducer,
  clinicSetting: clinicSettingsReducer,
  communication: communicationReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
