import { MATCH_PASSWORD_REQUEST, MATCH_PASSWORD_REQUEST_FAILURE, MATCH_PASSWORD_REQUEST_SUCCESS } from '../actionTypes'
import {
  MatchPasswordFailurePayload,
  MatchPasswordRequest,
  MatchPasswordRequestFailure,
  MatchPasswordRequestPayload,
  MatchPasswordRequestSuccess,
  MatchPasswordRequestSuccessPayload,
} from '../../schema/auth/matchPasswordSchema'

export const matchPasswordRequest = (payload: MatchPasswordRequestPayload): MatchPasswordRequest => ({
  type: MATCH_PASSWORD_REQUEST,
  payload,
})

export const matchPasswordRequestSuccess = (payload: MatchPasswordRequestSuccessPayload): MatchPasswordRequestSuccess => ({
  type: MATCH_PASSWORD_REQUEST_SUCCESS,
  payload,
})

export const matchPasswordRequestFailure = (payload: MatchPasswordFailurePayload): MatchPasswordRequestFailure => ({
  type: MATCH_PASSWORD_REQUEST_FAILURE,
  payload,
})
