import {
  ClinicAdminLoginFailurePayload,
  ClinicAdminLoginRequest,
  ClinicAdminLoginRequestFailure,
  ClinicAdminLoginRequestPayload,
  ClinicAdminLoginRequestSuccess,
  ClinicAdminLoginRequestSuccessPayload,
} from '../../schema/auth/loginSchema'
import { CLINIC_ADMIN_LOGIN_REQUEST, CLINIC_ADMIN_LOGIN_REQUEST_FAILURE, CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS } from '../actionTypes'

export const clinicAdminLoginRequest = (payload: ClinicAdminLoginRequestPayload): ClinicAdminLoginRequest => ({
  type: CLINIC_ADMIN_LOGIN_REQUEST,
  payload,
})

export const clinicAdminLoginRequestSuccess = (payload: ClinicAdminLoginRequestSuccessPayload): ClinicAdminLoginRequestSuccess => ({
  type: CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS,
  payload,
})

export const clinicAdminLoginRequestFailure = (payload: ClinicAdminLoginFailurePayload): ClinicAdminLoginRequestFailure => ({
  type: CLINIC_ADMIN_LOGIN_REQUEST_FAILURE,
  payload,
})
