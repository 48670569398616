import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import { CREATE_PASSWORD_REQUEST } from '../../actions/actionTypes'
import { createPasswordRequestFailure, createPasswordRequestSuccess } from '../../actions/authActions/createPasswordAction'

function* createPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v1/auth/password?is_owner=${payload?.payload?.is_owner}`, payload.payload)
    yield call(payload.payload.callback, response)
    yield put(
      createPasswordRequestSuccess({
        createPasswordData: response?.data?.detail,
      })
    )
    if (response) {
      successToaster(response?.data?.meta?.message)
    }
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      createPasswordRequestFailure({
        createPasswordError: e?.meta?.message,
      })
    )
  }
}

function* createPasswordWatch() {
  yield takeEvery(CREATE_PASSWORD_REQUEST, createPasswordRequestSaga)
}

export default function* createPasswordSaga() {
  yield all([createPasswordWatch()])
}
