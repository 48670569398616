import {
  SEND_OTP_REQUEST,
  SEND_OTP_REQUEST_FAILURE,
  SEND_OTP_REQUEST_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_REQUEST_FAILURE,
  VERIFY_OTP_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { otpActions, otpInterface } from '../../schema/auth/otpSchema'

const initialState: otpInterface = {
  verifyOTPLoader: false,
  verifyOTPError: null,
  sendOTPLoader: false,
  sendOTPError: null,
  mobile: '',
}

const otpReducer = (state = initialState, action: otpActions) => {
  switch (action.type) {
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        verifyOTPLoader: true,
      }

    case VERIFY_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        verifyOTPLoader: false,
        verifyOTPError: null,
      }
    case VERIFY_OTP_REQUEST_FAILURE:
      return {
        ...state,
        verifyOTPLoader: false,
        verifyOTPError: action?.payload?.verifyOTPError,
      }
    case SEND_OTP_REQUEST:
      return {
        ...state,
        sendOTPLoader: true,
      }

    case SEND_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        sendOTPLoader: false,
        mobile: action?.payload,
        sendOTPError: null,
      }
    case SEND_OTP_REQUEST_FAILURE:
      return {
        ...state,
        sendOTPLoader: false,
        mobile: null,
        sendOTPError: action?.payload?.sendOTPError,
      }
    default:
      return {
        ...state,
      }
  }
}

export default otpReducer
