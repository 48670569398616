import {
  CLINIC_ADMIN_RESET_PASSWORD_REQUEST,
  CLINIC_ADMIN_RESET_PASSWORD_REQUEST_FAILURE,
  CLINIC_ADMIN_RESET_PASSWORD_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { clinicAdminResetPasswordActions, ClinicAdminResetPasswordInterface } from '../../schema/auth/resetPasswordSchema'

const initialState: ClinicAdminResetPasswordInterface = {
  resetPasswordLoader: false,
  resetPasswordData: null,
  resetPasswordError: '',
}
const clinicAdminResetPasswordReducer = (state = initialState, action: clinicAdminResetPasswordActions) => {
  switch (action.type) {
    case CLINIC_ADMIN_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordLoader: true,
      }
    case CLINIC_ADMIN_RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        resetPasswordLoader: false,
        resetPasswordData: action.payload,
        resetPasswordError: null,
      }
    case CLINIC_ADMIN_RESET_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        resetPasswordLoader: false,
        resetPasswordData: null,
        resetPasswordError: action.payload.resetPasswordError,
      }
    default:
      return {
        ...state,
      }
  }
}
export default clinicAdminResetPasswordReducer
