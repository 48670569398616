import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { checkAPIParams, errorToaster, getAccountData, successToaster } from '../../../utils/helper'
import {
  CREATE_PATIENT_REQUEST,
  DELETE_PATIENT_REQUEST,
  GENERATE_RISK_SCORE_PMS_REQUEST,
  GET_PATIENT_REQUEST,
  GET_WELLNESS_REQUEST,
  SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_STATUS_REQUEST,
  VIEW_PATIENT_REQUEST,
} from '../../actions/actionTypes'
import {
  createPatientRequestFailure,
  createPatientRequestSuccess,
  deletePatientRequestFailure,
  deletePatientRequestSuccess,
  generateRiskWellnessPMSRequestFailureAction,
  generateRiskWellnessPMSRequestSuccessAction,
  getPatientRequestFailure,
  getPatientRequestSuccess,
  getWellnessProfileFailureAction,
  getWellnessProfileSuccessAction,
  patientUpdateStatusRequestFailure,
  patientUpdateStatusRequestSuccess,
  sendPateIntPreIntakeReminderRequestFailureAction,
  sendPateIntPreIntakeReminderRequestSuccessAction,
  updatePatientRequestFailure,
  updatePatientRequestSuccess,
  viewPatientCacheDataClearRequest,
  viewPatientRequestFailure,
  viewPatientRequestSuccess,
} from '../../actions/patient/patientAction'

const accountData = getAccountData()

// create patient
function* createPatientRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v1/patient`, payload.payload)
    if (payload.payload.callback) {
      yield call(payload.payload.callback, response.data)
    }
    // if needed in future
    successToaster(response?.data?.meta?.message)
    yield put(
      createPatientRequestSuccess({
        createPatientData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      createPatientRequestFailure({
        createPatientError: e?.meta?.message,
      })
    )
  }
}

// get all the patient (patient list)
function* getPatientRequestSaga(payload: any): any {
  // yield put(getPatientCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload.payload.search },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: 25 },
    ]
    if (payload.payload.clinic_id) {
      params.push({ key: 'clinic_id', value: payload.payload.clinic_id })
    }

    const url = `/v1/patient/get/all${checkAPIParams(params)}`
    const response = yield API.get(url)
    yield put(
      getPatientRequestSuccess({
        getPatientData: response.data?.detail,
        getPatientMeta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      getPatientRequestFailure({
        getPatientError: e?.meta?.message,
      })
    )
  }
}

// view patient details
function* viewPatientRequestSaga(payload: any): any {
  yield put(viewPatientCacheDataClearRequest())
  try {
    const response = yield API.get(`/v1/patient/${payload?.payload?.patient_id}?clinic_id=${payload?.payload?.clinic_id}`)
    yield call(payload.payload.callback, response?.data?.detail)
    // if needed in future
    // successToaster(response?.data?.meta?.message)
    yield put(
      viewPatientRequestSuccess({
        viewPatientData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      viewPatientRequestFailure({
        viewPatientError: e?.meta?.message,
      })
    )
  }
}

// update patient
function* updatePatientRequestSaga(payload: any): any {
  // if (accountData?.is_staff) {
  //   delete payload?.payload?.clinic
  // } else {
  //   delete payload?.payload?.clinic_id
  // }
  try {
    const response = yield API.put(
      `/v1/patient?patient_id=${payload?.payload?.patient_id}&clinic_id=${payload?.payload?.clinic_id}`,
      payload?.payload
    )
    // if needed in future
    if (payload.payload.callback) {
      yield call(payload.payload.callback, response.data)
    }
    successToaster(response?.data?.meta?.message)
    yield put(
      updatePatientRequestSuccess({
        updatePatientData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      updatePatientRequestFailure({
        updatePatientError: e?.meta?.message,
      })
    )
  }
}

// delete patient
function* deletePatientRequestSaga(payload: any): any {
  const staffUrl = `/v1/patient?patient_id=${payload?.payload?.patient_id}&clinic_id=${payload?.payload?.clinic_id}`
  const ownerUrl = `/v1/patient?patient_id=${payload?.payload?.patient_id}`
  try {
    const response = yield API.delete(accountData?.is_staff ? staffUrl : ownerUrl)
    yield call(payload.payload.callback, response.data)
    successToaster(response?.data?.meta?.message)
    yield put(
      deletePatientRequestSuccess({
        deletePatientData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      deletePatientRequestFailure({
        deletePatientError: e?.meta?.message,
      })
    )
  }
}

// update patient status
function* updatePatientStatusRequestSaga(payload: any): any {
  const updatedPayload = {
    clinic_id: payload?.payload?.clinic_id || undefined,
    status: payload?.payload?.status,
  }
  try {
    const response = yield API.put(`/v1/patient/${payload.payload?.patient_id}/status`, updatedPayload)
    yield call(payload.payload.callback, response.data)
    // if needed in future
    // successToaster(response?.data?.meta?.message)
    yield put(
      patientUpdateStatusRequestSuccess({
        patientStatusUpdateData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      patientUpdateStatusRequestFailure({
        patientStatusUpdateError: e?.meta?.message,
      })
    )
  }
}

// send pre-intake reminder
function* sendPatientPreIntakeReminderRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v1/pre-intake/form/reminder`, payload?.payload)
    if (payload.payload.callback) {
      yield call(payload.payload.callback, response?.data?.detail)
    }
    successToaster(response?.data?.meta?.message)
    yield put(sendPateIntPreIntakeReminderRequestSuccessAction(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(sendPateIntPreIntakeReminderRequestFailureAction(e?.meta?.message))
  }
}

// get Wellness profile data
function* getWellnessProfileRequest(payload: any): any {
  try {
    const response = yield API.get(`/v1/wellness-profile/${payload?.payload?.patient_id}?clinic_id=${payload?.payload?.clinic_id}`)
    yield put(getWellnessProfileSuccessAction(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(getWellnessProfileFailureAction(e?.meta?.message))
  }
}
function* generateWellnessRiskScoreDataRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`/v1/risk/profile/wellness/${payload.payload?.patient_id}`)
    yield put(generateRiskWellnessPMSRequestSuccessAction(response?.data?.detail))
  } catch (e: any) {
    yield put(generateRiskWellnessPMSRequestFailureAction(e?.message))
  }
}

function* createPatientSaga() {
  yield takeEvery(CREATE_PATIENT_REQUEST, createPatientRequestSaga)
}

function* getPatientSaga() {
  yield takeEvery(GET_PATIENT_REQUEST, getPatientRequestSaga)
}

function* viewPatientSaga() {
  yield takeEvery(VIEW_PATIENT_REQUEST, viewPatientRequestSaga)
}

function* updatePatientSaga() {
  yield takeEvery(UPDATE_PATIENT_REQUEST, updatePatientRequestSaga)
}

function* deletePatientSaga() {
  yield takeEvery(DELETE_PATIENT_REQUEST, deletePatientRequestSaga)
}

function* updatePatientStatusSaga() {
  yield takeEvery(UPDATE_PATIENT_STATUS_REQUEST, updatePatientStatusRequestSaga)
}

function* sendPatientPreIntakeReminderWatch() {
  yield takeEvery(SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST, sendPatientPreIntakeReminderRequestSaga)
}

function* getWellnessProfileWatch() {
  yield takeEvery(GET_WELLNESS_REQUEST, getWellnessProfileRequest)
}

function* generateWellnessRiskScoreDataRequestWatch() {
  yield takeEvery(GENERATE_RISK_SCORE_PMS_REQUEST, generateWellnessRiskScoreDataRequestSaga)
}

export default function* patientSaga() {
  yield all([
    createPatientSaga(),
    getPatientSaga(),
    viewPatientSaga(),
    updatePatientSaga(),
    deletePatientSaga(),
    updatePatientStatusSaga(),
    sendPatientPreIntakeReminderWatch(),
    getWellnessProfileWatch(),
    generateWellnessRiskScoreDataRequestWatch(),
  ])
}
