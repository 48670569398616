import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, getAccountData, successToaster } from '../../../utils/helper'
import { EDIT_OWNER_PROFILE_INFO_REQUEST, GET_OWNER_PROFILE_INFO_REQUEST } from '../../actions/actionTypes'
import {
  getOwnerProfileInfoCacheDataClearRequest,
  getOwnerProfileInfoRequestFailure,
  getOwnerProfileInfoRequestSuccess,
  putOwnerProfileInfoCacheDataClearRequest,
  putOwnerProfileInfoRequestFailure,
  putOwnerProfileInfoRequestSuccess,
} from '../../actions/ownerProfileActions/ownerProfileAction'

const accountData = getAccountData()

function* getOwnerProfileInfoRequestSaga(payload: any): any {
  yield put(getOwnerProfileInfoCacheDataClearRequest())
  try {
    const response = yield API.get(
      accountData?.is_staff
        ? `/v1/staff/${payload?.payload?.staff_id}?clinic_id=${payload.payload?.clinic_id}`
        : `/v1/owner/${payload?.payload?.owner_id}`
    )
    yield put(getOwnerProfileInfoRequestSuccess({ profileData: response?.data?.detail }))
  } catch (e: any) {
    yield put(getOwnerProfileInfoRequestFailure({ profileError: e?.detail }))
  }
}

function* putOwnerProfileInfoRequestSaga(payload: any): any {
  yield put(putOwnerProfileInfoCacheDataClearRequest())
  try {
    const editProfilePayload = payload?.payload?.profile_picture ? payload?.payload?.profile_picture : payload?.payload?.data
    const response = yield API.put(
      accountData?.is_staff
        ? `/v1/staff?staff_id=${payload?.payload?.staff_id}&clinic_id=${payload?.payload?.clinic_id}`
        : `/v1/owner/${payload?.payload?.owner_id}`,
      editProfilePayload
    )
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(putOwnerProfileInfoRequestSuccess({ profileData: response?.data?.detail }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    if (payload?.payload?.errorCallback) {
      payload?.payload?.errorCallback()
    }
    errorToaster(e?.meta?.message)
    yield put(putOwnerProfileInfoRequestFailure({ profileError: e?.meta?.message }))
  }
}

function* getOwnerProfileInfoSaga() {
  yield takeEvery(GET_OWNER_PROFILE_INFO_REQUEST, getOwnerProfileInfoRequestSaga)
}
function* putOwnerProfileInfoSaga() {
  yield takeEvery(EDIT_OWNER_PROFILE_INFO_REQUEST, putOwnerProfileInfoRequestSaga)
}

export default function* ownerProfileSaga() {
  yield all([getOwnerProfileInfoSaga(), putOwnerProfileInfoSaga()])
}
