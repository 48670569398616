import { Grid } from '@mui/material'
import { isSupported } from 'firebase/messaging'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Loader from './components/common/Loader'
import { onMessageListener, requestForToken } from './firebase'
import ErrorUi from './pages/ErrorUi'
import LandingPage from './pages/landingPage'
import { getClinicRequest } from './redux/actions/clinic/clinicAction'
import { RootState } from './redux/reducers/rootReducer'
import ProtectedRoute from './router/ProtectedRoute'
import { getAccountData, getLocalStorageItem, getOwnerData } from './utils/helper'
import pusher from './utils/pusher'

const accountData = getAccountData()

const Login = React.lazy(() => import('./pages/auth/Login'))
const ForgotPassword = React.lazy(() => import('./pages/auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'))
const CreatePassword = React.lazy(() => import('./pages/auth/CreatePassword'))
const SignUp = React.lazy(() => import('./pages/auth/signUp'))
const EmailVerified = React.lazy(() => import('./pages/auth/EmailVerified'))
const TermsAndCondition = React.lazy(() => import('./pages/auth/TermsCondition&PrivacyPolicy'))
const VerifyMobileNumber = React.lazy(() => import('./pages/auth/VerifyMobileNumber'))
const ChangePassword = React.lazy(() => import('./pages/auth/ChangePassword'))
const PageNotFound = React.lazy(() => import('./components/common/PageNotFound'))
const Clinic = React.lazy(() => import('./pages/clinic/index'))
const MyProfile = React.lazy(() => import('./pages/myProfile/MyProfile'))
const AddEditClinic = React.lazy(() => import('./pages/clinic/addEditClinic'))
const ManageMembers = React.lazy(() => import('./pages/members'))
const MainLayout = React.lazy(() => import('./components/layout/MainLayout'))
const LayoutWithAuthHeader = React.lazy(() => import('./components/layout/LayoutWithAuthHeader'))
const Patient = React.lazy(() => import('./pages/patient/index'))
const AddEditPatient = React.lazy(() => import('./pages/patient/addEditPatient'))
const EditMember = React.lazy(() => import('./pages/members/editMember'))
const ManageAppointment = React.lazy(() => import('./pages/appointment/index'))
const AddEditAppointment = React.lazy(() => import('./pages/appointment/addEditAppointment'))
const CheckoutAppointment = React.lazy(() => import('./pages/appointment/checkoutAppointment'))
const ViewAppointment = React.lazy(() => import('./pages/appointment/viewAppointment'))
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'))
const TermsAndConditions = React.lazy(() => import('./pages/TermAndConditionsStaticPage'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicyStaticPage'))
const ApproveClinic = React.lazy(() => import('./pages/approveClinic'))
const ManageOwners = React.lazy(() => import('./pages/owner'))
const AddEditOwner = React.lazy(() => import('./pages/owner/addEditOwner'))
const QSurvey = React.lazy(() => import('./components/clinic/addEditClinic/QSurvey'))
const MsaAgreement = React.lazy(() => import('./components/clinic/addEditClinic/MsaAgreement'))
const HealthHistory = React.lazy(() => import('./pages/healthHistory'))
const Communications = React.lazy(() => import('./pages/auth/Communications'))

// tslint:disable-next-line:class-name
interface routeInterface {
  path: string
  name: string
  exact: boolean
  component?: any
  private: boolean
  layout?: any
}

// module 'default' is for the default module for owner and staff both
// module 'dashboard' is for the dashboard
// module 'manage-members' for the manage member - include list,add and edit
// module 'manage-clinics' for the manage clinic - include list,add and edit
// module 'manage-patients' for the manage patient - include list,add and edit
// module 'manage-appointments' for the manage appointment - include list,add and edit
// module 'add-clinic' for the add clinic which one is not accessible for the staff
// module manage-owners' for the manage owner - include list,add and edit

const paths = [
  {
    path: '/sign-up',
    name: 'Sign Up',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<SignUp />} />,
    module: 'default',
  },
  {
    path: '/login',
    name: 'Login',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<Login />} />,
    module: 'default',
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<ForgotPassword />} />,
    module: 'default',
  },
  {
    path: '/recover-password/:recovery_token',
    name: 'Reset Password',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<ResetPassword />} />,
    module: 'default',
  },
  {
    path: '/create-password',
    name: 'Create Password',
    exact: true,
    private: true,
    layout: <LayoutWithAuthHeader component={<CreatePassword />} />,
    module: 'default',
  },
  {
    path: '/verify-mobile',
    name: 'Verify Mobile Number',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<VerifyMobileNumber />} />,
    module: 'default',
  },
  {
    path: '/email-verified/:recovery_token',
    name: 'Verify Email',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader restrictHeader={true} component={<EmailVerified />} />,
    module: 'default',
  },
  {
    path: '/change-password',
    name: 'Change Password',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Change Password'} component={<ChangePassword />} />,
    module: 'default',
  },
  {
    path: '/terms-and-condition',
    name: 'Terms Condition And Privacy Policy',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<TermsAndCondition />} />,
    module: 'default',
  },
  {
    path: '/communications',
    name: 'Communications',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<Communications />} />,
    module: 'default',
  },
  {
    path: '/profile',
    name: 'My Profile',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'My Profile'} component={<MyProfile />} />,
    module: 'default',
  },

  {
    path: '/patient',
    name: 'Manage Patient',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Patients'} component={<Patient />} />,
    module: 'manage-patients',
  },
  {
    path: '/members',
    name: 'Manage Member',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Members'} component={<ManageMembers />} />,
    module: 'manage-members',
  },
  {
    path: '/members/add',
    name: 'Add Member',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Add Member'} component={<EditMember />} />,
    module: 'manage-members',
  },
  {
    path: '/members/edit/:id',
    name: 'Edit Member Details',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Edit Member'} component={<EditMember isEdit={true} />} />,
    module: 'manage-members',
  },
  {
    path: '/members/view/:id',
    name: 'View Member Details',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'View Member'} component={<EditMember isEdit={true} />} />,
    module: 'manage-members',
  },
  {
    path: '/clinic',
    name: 'Manage Clinics',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Clinics'} component={<Clinic />} />,
    module: 'manage-clinics',
  },
  {
    path: '/clinic/add',
    name: 'Add Clinic',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Add Clinic'} component={<AddEditClinic />} />,
    module: 'add-clinic',
  },
  {
    path: '/clinic/practice-information/:clinicId',
    name: 'Practice Information',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Practice Information'} component={<QSurvey />} />,
    module: 'manage-clinics',
  },
  {
    path: '/clinic/msa-agreement/:clinicId',
    name: 'MSA Agreement',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'MSA Agreement'} component={<MsaAgreement />} />,
    module: 'manage-clinics',
  },

  {
    path: '/clinic/edit/:id',
    name: 'Edit Clinic',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Edit Clinic'} component={<AddEditClinic isEdit={true} />} />,
    module: 'manage-clinics',
  },
  {
    path: '/clinic/view/:id',
    name: 'View Clinic',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'View Clinic'} component={<AddEditClinic isEdit={true} />} />,
    module: 'manage-clinics',
  },
  {
    path: '/patient/add',
    name: 'Add Patient',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Add Patient'} component={<AddEditPatient />} />,
    module: 'manage-patients',
  },
  {
    path: '/patient/edit/:id/:clinicId',
    name: 'Edit Patient',
    exact: true,
    private: true,
    layout: (
      <MainLayout restrictHeader={false} restrictSidebar={false} name={'Edit Patient'} component={<AddEditPatient isEdit={true} />} />
    ),
    module: 'manage-patients',
  },
  {
    path: '/patient/view/:id',
    name: 'Edit Patient',
    exact: true,
    private: true,
    layout: (
      <MainLayout restrictHeader={false} restrictSidebar={false} name={'View Patient'} component={<AddEditPatient isEdit={true} />} />
    ),
    module: 'manage-patients',
  },
  {
    path: '/patient/health-history/:id/:clinicId',
    name: 'Health History',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Health History'} component={<HealthHistory />} />,
    module: 'manage-patients',
  },
  //
  {
    path: '/appointment',
    name: 'Manage Appointments',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Appointments'} component={<ManageAppointment />} />,
    module: 'manage-appointments',
  },
  {
    path: '/appointment/add',
    name: 'Add Appointment',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Add Appointment'} component={<AddEditAppointment />} />,
    module: 'manage-appointments',
  },
  {
    path: '/appointment/edit/:id',
    name: 'Edit Appointment',
    exact: true,
    private: true,
    layout: (
      <MainLayout
        restrictHeader={false}
        restrictSidebar={false}
        name={'Edit Appointment'}
        component={<AddEditAppointment isEdit={true} />}
      />
    ),
    module: 'manage-appointments',
  },
  {
    path: '/appointment/view/:id',
    name: 'View Appointment',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'View Appointment'} component={<ViewAppointment />} />,
    module: 'default',
  },
  {
    path: '/appointment/checkout/:id',
    name: 'Appointment',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Check Out'} component={<CheckoutAppointment />} />,
    module: 'manage-appointments',
  },
  // dashboard
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Dashboard'} component={<Dashboard />} />,
    module: 'dashboard',
  },
  {
    path: '/dashboard/appointment/view/:id',
    name: 'View Appointment',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'View Appointment'} component={<ViewAppointment />} />,
    module: 'default',
  },
  {
    path: '/dashboard/checkout/:id',
    name: 'Appointment',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Check Out'} component={<CheckoutAppointment />} />,
    module: 'default',
  },
  // Owner
  {
    path: '/owners',
    name: 'Manage Owners',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Owners'} component={<ManageOwners />} />,
    module: 'manage-owners',
  },
  {
    path: '/owners/add',
    name: 'Add Owner',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Add Owner'} component={<AddEditOwner />} />,
    module: 'manage-owners',
  },
  {
    path: '/owners/edit/:id',
    name: 'Edit Owner',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Edit Owner'} component={<AddEditOwner isEdit={true} />} />,
    module: 'manage-owners',
  },
  // Temporary admin tasks
  {
    path: '/approve-clinic',
    name: 'Approve Clinic',
    exact: true,
    private: true,
    layout: <ApproveClinic />,
    module: 'manage-clinics',
  },
  {
    path: '/*',
    name: 'Page Not Found',
    exact: true,
    private: false,
    layout: <PageNotFound />,
    module: 'default',
  },
  {
    path: '/tNc',
    name: 'Terms&Conditions',
    exact: true,
    private: false,
    layout: <TermsAndConditions />,
    module: 'default',
  },
  {
    path: '/pNp',
    name: 'Terms&Conditions',
    exact: true,
    private: false,
    layout: <PrivacyPolicy />,
    module: 'default',
  },
  {
    path: '/clinic-admin',
    name: 'Landing Page',
    exact: true,
    private: false,
    layout: <LandingPage />,
    module: 'default',
  },
]

const App = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const clinicData = useSelector((state: any) => state?.module?.clinicByStaffData)
  const { cookiesVal } = useSelector((state: RootState) => state.clinicFilter)
  const modules = useSelector((state: any) => state?.module?.moduleData)

  useEffect(() => {
    isSupported()?.then((res: any) => {
      if (res) {
        requestForToken().then(() => {
          onMessageListener()
            .then(() => {})
            .catch(() => {})
        })
      }
    })
  }, [])

  // filter is only for the staff , get selected clinic's assigned module
  const getAccessibleModules = () => {
    const assignedModules = getOwnerData()
      ?.assign_modules?.map((id: any) => {
        const module = modules?.find((module: any) => module?.ID === parseInt(id))
        return module ? module?.slug : null
      })
      .filter((slug: any) => slug !== null)
    return assignedModules || []
  }

  // filter data using the assigned module
  const validateRoute = () => {
    if (accountData?.is_staff) {
      return (clinicData !== null && accountData !== null) || (accountData?.is_staff && clinicData !== null && cookiesVal)
        ? paths.filter(
            (path: any) => getAccessibleModules().includes(`${path.module}`) || path.module === 'default' || path.module === 'dashboard'
          )
        : paths
    } else {
      return paths
    }
  }

  // get instant data using pusher
  useEffect(() => {
    const channel = pusher.subscribe('clinic_approve_channel')
    channel.bind(getOwnerData()?.id || '', (data: any) => {
      const localStorageData = JSON.parse(getLocalStorageItem('ownerData') as string)
      const newData = { ...localStorageData, clinic_approve: data?.clinic_approve }
      localStorage.setItem('ownerData', JSON.stringify(newData))
      if (location?.pathname?.includes('/clinic') && data?.clinic_approve) {
        dispatch(getClinicRequest({}))
      }
    })
    // eslint-disable-next-line
  }, [getOwnerData()?.id])

  // TODO: Hide ResizeObserver error in the browser
  useEffect(() => {
    const handleError = (e: any) => {
      if (e?.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    }

    window?.addEventListener('error', handleError)

    // Cleanup function to remove the event listener on unmount
    return () => {
      window?.removeEventListener('error', handleError)
    }
  }, [])

  return (
    <React.Suspense fallback={<Loader />}>
      <ErrorUi>
        <Routes>
          <Route path="/" element={<Navigate to="/clinic-admin" replace={true} />} />
          {validateRoute()?.map((res: routeInterface, i: number) => (
            <Route
              key={i}
              path={res?.path}
              element={
                res.private ? (
                  <ProtectedRoute path={res}>
                    <span>{res?.layout}</span>
                  </ProtectedRoute>
                ) : (
                  <Grid>{res.layout}</Grid>
                )
              }
            />
          ))}
        </Routes>
      </ErrorUi>
    </React.Suspense>
  )
}

export default App
