import { Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import clinicExamHeader from '../../../assets/images/v2/landingPage/clinicExamHeader.png'
import headerImage from '../../../assets/images/v2/landingPage/headerImage.png'

import clinicFour from '../../../assets/images/v2/landingPage/clinicExam/clinicFour.png'
import clinicOne from '../../../assets/images/v2/landingPage/clinicExam/clinicOne.png'
import clinicThree from '../../../assets/images/v2/landingPage/clinicExam/clinicThree.png'
import clinicTwo from '../../../assets/images/v2/landingPage/clinicExam/clinicTwo.png'
import theme from '../../../theme'

const useStyles = makeStyles(() => ({
  headerBackground: {
    backgroundImage: `url(${headerImage})`,
    display: 'flex',
    justifyContent: 'center',
    backgroundSize: '100% 298px',
    backgroundRepeat: 'no-repeat',
    marginTop: '90px !important',
  },
  headerTransparentBox: {
    height: 'fit-content',
    width: '60%',
    [theme.breakpoints.down(1700)]: {
      width: '75%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '85%',
    },
    [theme.breakpoints.down(960)]: {
      width: '98%',
    },
  },
  headerWhiteBox: {
    height: '358px',
    [theme.breakpoints.down(1100)]: {
      height: '280px',
    },
    [theme.breakpoints.down(768)]: {
      marginTop: '80px',
      height: '240px',
    },
    [theme.breakpoints.down(500)]: {
      height: '290px',
      marginTop: '70px',
    },
  },
  headerTextOne: {
    fontSize: '40px',
    fontWeight: '600',
    lineHeight: '56px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      lineHeight: '45px',
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '25px',
      lineHeight: '35px',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px',
      lineHeight: '25px',
    },
  },
  descriptionText: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    color: theme.palette.common.black50,
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      lineHeight: '25px',
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  imageTag: {
    height: '480px',
    width: '430px',
    [theme.breakpoints.down('lg')]: {
      height: '435px',
      width: '385px',
    },
    [theme.breakpoints.down(1100)]: {
      height: '380px',
      width: '355px',
    },
    [theme.breakpoints.down(768)]: {
      display: 'none',
    },
  },
  signInBtn: {
    height: '36px',
    width: '91px',
    padding: '6px 16px',
    border: `1px solid ${theme.palette.v2.primary.main} !important`,
    borderRadius: '4px',
    color: `${theme.palette.v2.primary.main} !important`,
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: `${theme.palette.v2.primary.main} !important`,
    },
  },
  signUpBtn: {
    height: '36px',
    width: '91px',
    padding: '6px 16px',
    borderRadius: '4px',
    background: `${theme.palette.v2.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    textDecoration: 'none',
    textAlign: 'center',
    marginTop: '20px',
    '&:hover': {
      background: `${theme.palette.v2.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  logoItemsBox: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '20px',
    padding: '30px 26px',
    height: '255px',
    '&:hover': {
      '& .second-child': {
        color: theme.palette.primary.main,
      },
      border: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('md')]: {
      height: '295px',
    },
    [theme.breakpoints.between(700, 960)]: {
      height: '255px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '250px',
    },
  },
  logoItemsText: {
    fontSize: '18px',
    lineHeight: '25px',
    textTransform: 'uppercase',
    fontWeight: 700,
    margin: '10px 0',
  },
  logoItemsDescription: {
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
  },
  purpleColor: {
    color: theme.palette.v2.secondary,
  },
  greenMainColor: {
    color: theme.palette.primary.main,
  },
}))

const clinicLogoItems = [
  {
    imageLogo: <img src={clinicOne} alt="clinicOne" />,
    heading: 'manage clinic members',
    description: 'Efficiently & seamlessly manage your dental team members with ease.',
  },
  {
    imageLogo: <img src={clinicTwo} alt="clinicTwo" />,
    heading: 'manage clinics',
    description: 'Simplify clinic management & Take needed actions to maintain optimal clinic operations.',
  },
  {
    imageLogo: <img src={clinicThree} alt="clinicThree" />,
    heading: 'manage patients',
    description: 'Efficiently manage patients in your dental practice. Add and assign new patients seamlessly.',
  },
  {
    imageLogo: <img src={clinicFour} alt="clinicFour" />,
    heading: 'manage appointments',
    description: 'Effortlessly handle appointments and ensure smooth scheduling processes.',
  },
]

const SignInComponent = () => {
  const classes = useStyles()
  const palette = theme.palette
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Box className={classes.headerBackground}>
        <Box className={classes.headerTransparentBox}>
          <Box
            className={`${classes.headerWhiteBox} d-flex justify-content-between`}
            width={'100%'}
            mt={'110px'}
            borderRadius={'20px 20px 0 0'}
            p={'24px'}
            gap={'20px'}
            sx={{ backgroundColor: palette.common.white }}
          >
            <Box
              className={`d-flex flex-column justify-center`}
              maxWidth={'80%'}
              sx={{
                [theme.breakpoints.down(768)]: {
                  width: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <Typography className={classes.headerTextOne}>
                Empowering&nbsp;<span className={classes.purpleColor}>Dental&nbsp;</span>
                <span className={classes.greenMainColor}>Practice Management</span>
              </Typography>
              <Typography className={classes.descriptionText}>
                Simplify your dental practice management with the OraQ Clinic Owner Portal. Streamline member management, clinic operations,
                patient care, and appointment scheduling.
              </Typography>
              <Box className={`d-flex`}>
                <Button className={classes.signUpBtn} onClick={() => navigate({ pathname: '/sign-up' })}>
                  SIGN UP
                </Button>
                <Button
                  variant="outlined"
                  className={classes.signInBtn}
                  sx={{ marginLeft: '10px' }}
                  onClick={() => navigate({ pathname: '/login' })}
                >
                  SIGN IN
                </Button>
              </Box>
            </Box>

            {/* image here */}
            <Box mt={'-75px'}>{<img src={clinicExamHeader} alt="clinicExamHeader" className={classes.imageTag} />}</Box>
          </Box>
        </Box>
      </Box>

      <Box
        className={`d-flex justify-center mt-30`}
        sx={{
          [theme.breakpoints.down(1100)]: {
            marginTop: '40px !important',
          },
        }}
      >
        <Grid
          container={true}
          className={`d-flex`}
          justifyContent="space-between"
          p={'0 30px'}
          columnSpacing={2}
          maxWidth={1300}
          sx={{
            [theme.breakpoints.between(768, 960)]: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr', // Change to 2 columns for screens below 'sm' breakpoint
            },
            [theme.breakpoints.down(768)]: {
              display: 'grid',
              gridTemplateColumns: '1fr', // Change to 1 column for screens below 'xs' breakpoint
            },
          }}
        >
          <Grid item={true} lg={6} md={6}>
            <Typography className={classes.headerTextOne}>
              Streamline&nbsp;<span className={classes.purpleColor}>Your&nbsp;</span>
              <span className={classes.greenMainColor}>Dental Practice Management</span>
            </Typography>
          </Grid>

          <Grid item={true} lg={6} md={6}>
            <Typography className={classes.descriptionText}>
              Take control of your dental practice with the comprehensive tools and features of the OraQ Clinic Owner Portal. Effortlessly
              manage your members, clinics, patients, and appointments, all in one place.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={`d-flex justify-center mt-30`}>
        <Grid container={true} p={'0 30px'} spacing={2} mt={'20px'} maxWidth={1300}>
          {clinicLogoItems &&
            clinicLogoItems?.map((res: any, index: number) => (
              <Grid item={true} key={index} xl={3} lg={3} md={4} sm={6} xs={12}>
                <Box className={`${classes.logoItemsBox} d-flex flex-column`}>
                  <Box className={`d-flex justify-center`}>{res?.imageLogo}</Box>
                  <Typography className={`${classes.logoItemsText} d-flex justify-center second-child`}>{res?.heading}</Typography>
                  <Typography className={`${classes.logoItemsDescription} d-flex justify-center`}>{res?.description}</Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </React.Fragment>
  )
}

export default SignInComponent
