import React from 'react'
import { useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'
import LRFStepsVerified from './LRFStepVerified'

const ProtectedRoute = ({ children }: any) => {
  const auth = localStorage.getItem('ownerToken')
  const location = useLocation()
  const redirect = location?.pathname?.split('/').slice(1).join('/')

  return auth ? <LRFStepsVerified>{children}</LRFStepsVerified> : <Navigate to="/login" state={redirect} />
}

export default ProtectedRoute
