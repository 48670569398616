import {
  GetTCFailurePayload,
  GetTCRequest,
  GetTCRequestFailure,
  GetTCRequestPayload,
  GetTCRequestSuccess,
  GetTCSuccessPayload,
  TermsConditionsPrivacyPolicyRequest,
  TermsConditionsPrivacyPolicyRequestFailure,
  TermsConditionsPrivacyPolicyRequestFailurePayload,
  TermsConditionsPrivacyPolicyRequestPayload,
  TermsConditionsPrivacyPolicyRequestSuccess,
  TermsConditionsPrivacyPolicyRequestSuccessPayload,
} from '../../schema/auth/termsConditionPrivacyPolicy'
import {
  GET_TC_REQUEST,
  GET_TC_REQUEST_FAILURE,
  GET_TC_REQUEST_SUCCESS,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS,
} from '../actionTypes'

export const termsConditionPrivacyPolicyRequest = (
  payload: TermsConditionsPrivacyPolicyRequestPayload
): TermsConditionsPrivacyPolicyRequest => ({
  type: TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST,
  payload,
})

export const termsConditionPrivacyPolicySuccess = (
  payload: TermsConditionsPrivacyPolicyRequestSuccessPayload
): TermsConditionsPrivacyPolicyRequestSuccess => ({
  type: TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS,
  payload,
})

export const termsConditionPrivacyPolicyFailure = (
  payload: TermsConditionsPrivacyPolicyRequestFailurePayload
): TermsConditionsPrivacyPolicyRequestFailure => ({
  type: TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE,
  payload,
})

export const GetTCDataRequest = (payload: GetTCRequestPayload): GetTCRequest => ({
  type: GET_TC_REQUEST,
  payload,
})

export const GetTCDataSuccess = (payload: GetTCSuccessPayload): GetTCRequestSuccess => ({
  type: GET_TC_REQUEST_SUCCESS,
  payload,
})

export const GetTCDataFailure = (payload: GetTCFailurePayload): GetTCRequestFailure => ({
  type: GET_TC_REQUEST_FAILURE,
  payload,
})
