import { CHANGE_APPOINTMENT_INFO, UPDATE_APPOINTMENT_INFO, UPDATE_APPOINTMENT_INFO_FAILURE } from '../../actions/actionTypes'
import { CreateAppointmentRequestPayload } from '../../schema/appointment/appointmentScheme'

const initialState: CreateAppointmentRequestPayload = {
  data: {
    patient_name: '',
    patient_contact_number: '',
    clinic_name: '',
    clinic_id: '',
    dentist_name: '',
    dentist_id: '',
    assistant_name: '',
    assistant_id: '',
    hygienist_name: '',
    hygienist_id: '',
    appointment_type: '',
    appointment_date: '',
    appointment_time: '',
  },
}

export const appointmentLocalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_APPOINTMENT_INFO:
      return {
        ...state,
        data: action.payload,
      }

    case UPDATE_APPOINTMENT_INFO_FAILURE:
      return {
        ...state,
        data: {
          patient_name: '',
          patient_contact_number: '',
          clinic_name: '',
          clinic_id: '',
          dentist_name: '',
          dentist_id: '',
          assistant_name: '',
          assistant_id: '',
          hygienist_name: '',
          hygienist_id: '',
          appointment_type: '',
          appointment_date: '',
          appointment_time: '',
        },
      }

    case CHANGE_APPOINTMENT_INFO:
      const temp = state.data
      const { key, value } = action.payload as any
      temp[key] = value
      return {
        ...state,
      }
    default:
      return { ...state }
  }
}
