import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import { CLINIC_ADMIN_LOGIN_REQUEST } from '../../actions/actionTypes'
import { clinicAdminLoginRequestFailure, clinicAdminLoginRequestSuccess } from '../../actions/authActions/loginAction'

function* clinicAdminLoginRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v1/auth/login?is_owner=${payload?.payload?.loginPayload?.isOwner}`, payload?.payload?.loginPayload)
    yield call(payload?.payload?.callback, response?.data)
    yield put(clinicAdminLoginRequestSuccess({ loginData: response?.data }))
    if (response?.data?.status === 200) {
      successToaster('Login Successful!')
    }
  } catch (e: any) {
    if (e?.code !== 403) {
      errorToaster(e?.meta?.message)
    }
    // TODO: future use
    // if (payload?.payload?.errorCallback()) {
    //   payload?.payload?.errorCallback()
    // }
    yield put(clinicAdminLoginRequestFailure({ loginError: e }))
  }
}

function* postClinicAdminLoginSaga() {
  yield takeEvery(CLINIC_ADMIN_LOGIN_REQUEST, clinicAdminLoginRequestSaga)
}

export default function* clinicAdminLoginSaga() {
  yield all([postClinicAdminLoginSaga()])
}
