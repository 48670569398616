import { all, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import {
  ADD_CARD_REQUEST,
  GET_CARDS_REQUEST,
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST,
  REMOVE_CARD_REQUEST,
  UNSUBSCRIBE_REQUEST,
  UPDATE_PAYMENT_METHOD_REQUEST,
} from '../../actions/actionTypes'
import {
  getCardsAction,
  getCardsFailureAction,
  getCardsSuccessAction,
  getSubscriptionPlanHistoryFailure,
  getSubscriptionPlanHistorySuccess,
  getSubscriptionPlansFailure,
  getSubscriptionPlansSuccess,
  RemoveCardFailureAction,
  RemoveCardSuccessAction,
  unSubscriptionPlanFailure,
  unSubscriptionPlanSuccess,
  updatePaymentMethodCardSuccessAction,
} from '../../actions/subscriptionPlans/subscriptionPlansAction'

function* getSubscriptionPlans(): any {
  try {
    const response = yield API.get(`v1/admin/subscription/plan`)
    yield put(getSubscriptionPlansSuccess(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      getSubscriptionPlansFailure({
        allMemberError: e?.meta?.message,
      })
    )
  }
}
function* unSubscriptionPlans(payload: any): any {
  try {
    const response = yield API.put(
      `v1/clinic/${payload?.payload?.clinic_id}/subscription/${payload?.payload?.subscription_plan_id}/unsubscribe`
    )
    yield put(unSubscriptionPlanSuccess(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(unSubscriptionPlanFailure(e?.meta?.message))
  }
}

// get subscription plan history
function* getSubscriptionPlanHistory(payload: any): any {
  try {
    const response = yield API.get(`v1/clinic/${payload?.payload?.clinic_id}/payment_details`)
    yield put(
      getSubscriptionPlanHistorySuccess({
        planHistoryData: response?.data?.detail,
        planHistoryMeta: response.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(getSubscriptionPlanHistoryFailure(e?.meta?.message))
  }
}

function* getCardsSaga(payload: any): any {
  try {
    const response = yield API.get(`v1/clinic/owner/card_details?clinic_id=${payload.payload}`)
    yield put(getCardsSuccessAction(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(getCardsFailureAction())
  }
}

function* addCardSaga(payload: any): any {
  try {
    const response = yield API.put(`/v1/clinic/owner/payment_method`, payload.payload)
    yield put(getCardsAction(response.data.detail.clinic_id))
    successToaster(response?.data?.meta?.message)
    if (payload?.payload?.callback) {
      payload?.payload?.callback()
    }
  } catch (e: any) {
    errorToaster(e?.meta?.message)
  }
}

function* RemoveCardSaga(payload: any): any {
  try {
    const response = yield API.delete(`v1/clinic/owner/payment_method/${payload.payload.payment_method_id}`, payload.payload)
    yield put(RemoveCardSuccessAction(response?.data?.detail))
    successToaster(response?.data?.meta?.message)
    if (payload?.payload?.callback) {
      payload?.payload?.callback()
    }
  } catch (e: any) {
    if (payload?.payload?.errorCallback) {
      payload?.payload?.errorCallback(e)
    }
    yield put(RemoveCardFailureAction(e?.meta?.message))
    errorToaster(e?.meta?.message)
  }
}

function* updatePaymentMethodSaga(payload: any): any {
  try {
    const response = yield API.put(`v1/clinic/payment_method/update`, payload.payload)
    yield put(updatePaymentMethodCardSuccessAction(response?.data?.detail))
    if (payload?.payload?.callback) {
      payload?.payload?.callback()
    }
  } catch (e: any) {
    errorToaster(e?.meta?.message)
  }
}
function* getSubscriptionPlansSaga() {
  yield takeEvery(GET_SUBSCRIPTION_PLANS_REQUEST, getSubscriptionPlans)
}
function* getUnSubscriptionPlansSaga() {
  yield takeEvery(UNSUBSCRIBE_REQUEST, unSubscriptionPlans)
}

function* getSubscriptionPlanHistorySaga() {
  yield takeEvery(GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST, getSubscriptionPlanHistory)
}

function* getCardsWatch() {
  yield takeEvery(GET_CARDS_REQUEST, getCardsSaga)
}

function* addCardWatch() {
  yield takeEvery(ADD_CARD_REQUEST, addCardSaga)
}
function* removeCardWatch() {
  yield takeEvery(REMOVE_CARD_REQUEST, RemoveCardSaga)
}

function* updatePaymentMethodWatch() {
  yield takeEvery(UPDATE_PAYMENT_METHOD_REQUEST, updatePaymentMethodSaga)
}
export default function* subscriptionPlansSaga() {
  yield all([
    getSubscriptionPlansSaga(),
    getCardsWatch(),
    addCardWatch(),
    getSubscriptionPlanHistorySaga(),
    getUnSubscriptionPlansSaga(),
    removeCardWatch(),
    updatePaymentMethodWatch(),
  ])
}
