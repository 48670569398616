import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../redux/reducers/rootReducer'
import { getLocalStorageItem } from '../utils/helper'

const LRFStepsVerified = ({ children }: any) => {
  const location = useLocation()
  const history = useNavigate()
  const localStorageData = JSON.parse(getLocalStorageItem('ownerData') as string)
  const { updateOwnerDetailState } = useSelector((state: RootState) => state.ownerProfileInfo)

  // @ts-ignore
  useEffect(() => {
    if (localStorageData?.is_mobile_verified) {
      if (localStorageData?.policy_accepted) {
        if (localStorageData?.temp_password && !localStorageData?.password_updated) {
          history({
            pathname: '/create-password',
          })
        }
      } else {
        history({
          pathname: '/terms-and-condition',
        })
      }
    } else {
      if (!updateOwnerDetailState && !localStorageData?.is_staff) {
        history({
          pathname: '/verify-mobile',
        })
      }
    }
    // eslint-disable-next-line
  }, [
    location?.pathname,
    history,
    localStorageData?.is_mobile_verified,
    localStorageData?.policy_accepted,
    localStorageData?.password_updated,
    localStorageData?.temp_password,
    localStorageData?.is_communication_preferences,
  ])

  return children
}

export default LRFStepsVerified
