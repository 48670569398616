import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { HeathHistoryCompleted } from '../../../utils/constants'
import { arrayToString, dateFormat, errorToaster, removeRefFromJson, stringToArray, successToaster } from '../../../utils/helper'
import { checkSleep, checkSleepLocal, getYesNo, passTrueFalse } from '../../../utils/preIntakeConstant/preIntakeConstant'
import {
  DOWNLOAD_PRE_INTAKE_PDF_REQUEST,
  GET_FIELDS_REQUEST,
  GET_PRE_INTAKE_DATA_REQUEST,
  PUT_PRE_INTAKE_DATA_REQUEST,
} from '../../actions/actionTypes'
import {
  downloadPreIntakePdfFailAction,
  downloadPreIntakePdfSuccessAction,
  getFieldsFailAction,
  getFieldsSuccessAction,
  getPreIntakeDataFailAction,
  getPreIntakeDataSuccessAction,
  putPreIntakeDataFailAction,
  putPreIntakeDataSuccessAction,
  updateCommunicationPreferencesAction,
  updateDentalInfoAction,
  updateDentistInsuranceInfoAction,
  updateEmergencyContactInfoAction,
  updateGeneralHealthAction,
  updateLetsGetToKnowYouBetterAction,
  updateMedicalHealthHistoryAction,
  updatePatientInfoAction,
  updateSleepQualityAction,
} from '../../actions/preIntake/preIntakeActions'

function* getPreIntakeDataSaga(payload: any): any {
  try {
    const response = yield API.get(`v1/pre-intake/form/${payload.payload}`)
    const data = response?.data?.detail
    const patientDetails = response?.data?.detail?.patient_details
    const preIntakeData = response?.data?.detail?.pre_intake
    // Set data in global redux to use for revert/discard changes (future usages)

    /* * Start ---->  * Update all pre-intake components value based on api response */

    // Patient Information
    yield put(
      updatePatientInfoAction({
        firstName: patientDetails?.first_name,
        middleName: patientDetails?.middle_name,
        lastName: patientDetails?.last_name,
        emailAddress: patientDetails?.email,
        gender: patientDetails?.gender,
        genderOther: patientDetails?.gender_other,
        sex: patientDetails?.sex,
        race: patientDetails?.race,
        raceOther: patientDetails?.race_other,
        mobilePhoneNumber: patientDetails?.mobile_phone_number,
        homePhoneNumber: patientDetails?.home_phone_number,
        dateOfBirth: patientDetails?.date_of_birth,
        address: patientDetails?.address,
        city: patientDetails?.city,
        postalCode: patientDetails?.postal_code,
        state: patientDetails?.state,
        country: patientDetails?.country,
      })
    )

    // Emergency Contact Information
    const relationshipStatus = ['Spouse', 'Parent', 'Friend', 'Family Member']
    yield put(
      updateEmergencyContactInfoAction({
        emergencyContactPerson: preIntakeData?.emergency_contact,
        emergencyContactNumber: preIntakeData?.emergency_contact_phone_number,
        relationshipToEmergencyContactPerson:
          preIntakeData?.relationship_to_emergency_contact &&
          (relationshipStatus.includes(preIntakeData?.relationship_to_emergency_contact)
            ? preIntakeData?.relationship_to_emergency_contact
            : 'Other'),
        other: relationshipStatus?.includes(preIntakeData?.relationship_to_emergency_contact)
          ? ''
          : preIntakeData?.relationship_to_emergency_contact,
      })
    )

    // Dental Insurance Information
    const dentistInsuranceInfo = [] as any
    if (preIntakeData?.insurance?.length) {
      preIntakeData?.insurance?.map((res: any) => {
        dentistInsuranceInfo.push({
          insuranceProvider: res.insurance_provider || '',
          insuranceGroup: res.insurance_group_number || '',
          insuranceID: res.insurance_id || '',
          planMemberName: res.plan_member_name || '',
          planMemberDob: res.plan_member_dob || null,
          position: res?.position || 0,
        })
        return true
      })
    } else {
      dentistInsuranceInfo.push({
        insuranceProvider: '',
        insuranceGroup: '',
        insuranceID: '',
        planMemberName: '',
        planMemberDob: null,
        position: 0,
      })
    }

    yield put(updateDentistInsuranceInfoAction(dentistInsuranceInfo))

    // Let’s get to know you better
    yield put(
      updateLetsGetToKnowYouBetterAction({
        occupation: preIntakeData?.occupation,
        isNightShiftInvolvedWithYourOccupation: getYesNo(preIntakeData?.does_your_work_involve_night_shift),
        maritalStatus: preIntakeData?.marital_status || '',
        referredBy: preIntakeData?.referred_by,
        doYouHaveDentalInsurance: getYesNo(preIntakeData?.dental_insurance),
      })
    )

    // General Health
    yield put(
      updateGeneralHealthAction({
        historyOfSmoking: preIntakeData?.history_of_smoking,
        alcoholAverageWeek: preIntakeData?.alcohol_usage_in_week,
        drugUse: getYesNo(preIntakeData?.recreational_drug_use),
        drugInputUse: preIntakeData?.recreational_drug_use_other,
        heightUnit: preIntakeData?.height_unit || 'ft',
        heightFT: preIntakeData?.height_unit === 'ft' ? preIntakeData?.height?.split('.')[0] : '',
        heightIN: preIntakeData?.height_unit === 'ft' ? preIntakeData?.height?.split('.')[1] || '' : '',
        heightCM: preIntakeData?.height_unit === 'cm' ? preIntakeData?.height : '',
        weightUnit: preIntakeData?.weight_unit || 'lb',
        weight: preIntakeData?.weight,
      })
    )

    // Dental Information
    yield put(
      updateDentalInfoAction({
        dentalAnxiety: preIntakeData?.dental_anxiety,
        reasonForSchedulingThisVisit: preIntakeData?.reason_for_scheduling_this_visit || '',
        nameOfPreviousOffice: preIntakeData?.previous_dental_office_name,
        lastVisitAtDentistOffice: preIntakeData?.last_see_dental_hygienist,
        lastTimeYouHadDentalXrayTaken: preIntakeData?.date_of_last_dental_x_ray,
        doesYourPhysicianRecommendAntibiotic: getYesNo(preIntakeData?.antibiotic_premedication_require_prior_dental_appointments),
        antibioticPremeditation: preIntakeData?.antibiotic_premedication,
        feelDentalHealth: preIntakeData?.dental_health_average || '',
        checkIfYouHaveEverHadFollowing: stringToArray(preIntakeData?.dental_info) || [],
        rateAppearanceOfYourSmile: preIntakeData?.appearance_smile_scale_to_10,
        correctAppearanceOfYourSmile: getYesNo(preIntakeData?.wish_correct_appearance_smile),
        hadWhiterSmile: getYesNo(preIntakeData?.wish_whiter_smile),
        doYouSnackMoreThatDay: getYesNo(preIntakeData?.snack_3x_day),
        toothpasteBrand: preIntakeData?.brand_toothpaste,
        toothExtractedInLastThreeYear: getYesNo(preIntakeData?.tooth_extracted_last_3_year),
        fillingsInLastThreeYear: preIntakeData?.filling_had_in_last_3_year,
        familyHistoryOfCavities: getYesNo(preIntakeData?.family_history_of_cavities_toothloss),
        homeCareProtectiveFactors: stringToArray(preIntakeData?.homecare_protective_factors) || [],
      })
    )

    // Sleep Quality

    yield put(
      updateSleepQualityAction({
        diagnosedSleepApnea: getYesNo(preIntakeData?.diagnosed_sleep_apnea_ever),
        neckCircumferenceMeasured: preIntakeData?.neck_circumference,
        snoreLoudly: preIntakeData?.loud_snore,
        feelDuringDay: getYesNo(preIntakeData?.fatigue_daily_bases),
        gaspDuringSleep: getYesNo(preIntakeData?.breathing_stop_sleep),
        difficultyBreathingNose: getYesNo(preIntakeData?.difficulty_breathing_through_your_nose),
        useCPAPMachine: getYesNo(preIntakeData?.cpap_machine),
        useOralAppliance: getYesNo(preIntakeData?.oral_appliance_sleep),
        additionalSleep: [
          checkSleepLocal(preIntakeData?.sitting_reading_epworth_scale),
          checkSleepLocal(preIntakeData?.watching_tv_epworth_scale),
          checkSleepLocal(preIntakeData?.sitting_inactive_epworth_scale),
          checkSleepLocal(preIntakeData?.being_passenger_car_epworth_scale),
          checkSleepLocal(preIntakeData?.lying_down_afternoon_epworth_scale),
          checkSleepLocal(preIntakeData?.sitting_talking_someone_epworth_scale),
          checkSleepLocal(preIntakeData?.sitting_quietly_lunch_epworth_scale),
          checkSleepLocal(preIntakeData?.stopping_fewminutes_driving_epworth_scale),
        ],
      })
    )

    // Medical Health History

    yield put(
      updateMedicalHealthHistoryAction({
        physicianName: preIntakeData?.physicians_name,
        physicianContactNumber: preIntakeData?.physician_phone_number,
        dateOfLastVisit: preIntakeData?.last_visit_to_physician,
        seriousIllnessesOrOperations: getYesNo(preIntakeData?.serious_illness_or_operation_existance),
        seriousIllnessesOrOperationsInput: preIntakeData?.serious_illness_or_operation_name,
        takingMedicationsOrSupplements: getYesNo(preIntakeData?.currently_taking_any_medication),
        takingMedicationsOrSupplementsInput: preIntakeData?.medication_list,
        shortnessBreath: getYesNo(preIntakeData?.chest_pain_shortness_breath),
        nursing: getYesNo(preIntakeData?.nursing),
        takingBirthControl: getYesNo(preIntakeData?.birth_control),
        pregnant: getYesNo(preIntakeData?.pregnant),
        medicalConditions: stringToArray(preIntakeData?.medical_conditions)?.map((condition: string) => parseInt(condition)) || [],
        medicalConditionsOther: preIntakeData?.medical_conditions_other || '',
        checkboxListTwo: stringToArray(preIntakeData?.allergies_list)?.map((allergies: string) => parseInt(allergies)) || [],
        checkboxListTwoOtherInput: preIntakeData?.allergies_list_other || '',
      })
    )

    // Communication Preferences
    yield put(
      updateCommunicationPreferencesAction({
        appearanceOfYourTeeth: preIntakeData?.appearance_teeth_scale,
        feelIfYouWereToLoseTooth: preIntakeData?.loose_tooth_feeling_scale,
        yourTeethFunctionWell: preIntakeData?.chew_importance_scale,
        keepYourTeethForRestOfYourLife: preIntakeData?.importancy_of_teeth_health_scale,
        concernsWithAppearanceOfYourTeeth: getYesNo(preIntakeData?.concerns_appearance_teeth_existance),
        concernsWithAppearanceOfYourTeethReason: preIntakeData?.concerns_appearance_teeth_existance_explain,
        teethSensitive: getYesNo(preIntakeData?.teeth_sensitivity),
        teethSensitiveReason: preIntakeData?.teeth_sensitivity_answer_yes,
        describeYourselfMore1: preIntakeData?.describe_yourself_1,
        describeYourselfMore2: preIntakeData?.describe_yourself_2,
      })
    )
    yield put(getPreIntakeDataSuccessAction(data))

    /* * End  * */
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(getPreIntakeDataFailAction())
  }
}

function* putPreIntakeDataSaga(payload: any): any {
  try {
    const { preIntakeData } = yield select((state: any) => state?.preIntake) as any

    const {
      patientInformation,
      emergencyContactInfo,
      letsGetToKnowYouBetter,
      dentistInsuranceInfo,
      generalHealth,
      dentalInfo,
      sleepQuality,
      medicalHealthHistory,
      communicationPreferences,
    } = payload.payload?.preIntakeData
    const data = {
      // Patient Information
      consent: 'I consent to collection, disclosure and use of my personal information as set out above',
      // first_name: patientInformation?.firstName,
      // middle_name: patientInformation?.middleName,
      // last_name: patientInformation?.lastName,
      // date_of_birth: patientInformation?.dateOfBirth,
      // address: patientInformation?.address,
      // city: patientInformation?.city,
      // country: patientInformation?.country,
      // state: patientInformation?.state,
      // postal_code: patientInformation?.postalCode,
      // home_phone_number: patientInformation?.homePhoneNumber,
      // mobile_phone_number: patientInformation?.mobilePhoneNumber,
      // email: patientInformation?.emailAddress,
      // gender: patientInformation?.gender,

      device: payload.payload?.device,

      // Emergency Contact

      emergency_contact: emergencyContactInfo?.emergencyContactPerson,
      emergency_contact_phone_number: emergencyContactInfo?.emergencyContactNumber,
      relationship_to_emergency_contact:
        'Other' === emergencyContactInfo?.relationshipToEmergencyContactPerson
          ? emergencyContactInfo?.other
          : emergencyContactInfo?.relationshipToEmergencyContactPerson,

      // Let’s get to know you better
      occupation: letsGetToKnowYouBetter?.occupation,
      does_your_work_involve_night_shift: passTrueFalse(letsGetToKnowYouBetter?.isNightShiftInvolvedWithYourOccupation),
      referred_by: letsGetToKnowYouBetter?.referredBy,
      marital_status: letsGetToKnowYouBetter?.maritalStatus,
      dental_insurance: passTrueFalse(letsGetToKnowYouBetter?.doYouHaveDentalInsurance),

      // General Health
      history_of_smoking: generalHealth?.historyOfSmoking,
      alcohol_usage_in_week: generalHealth?.alcoholAverageWeek,
      recreational_drug_use: passTrueFalse(generalHealth?.drugUse),
      recreational_drug_use_other: generalHealth?.drugInputUse,
      height:
        generalHealth?.heightUnit === 'ft' && generalHealth?.heightFT
          ? `${generalHealth?.heightFT}.${generalHealth?.heightIN ? generalHealth?.heightIN : 0}`
          : generalHealth?.heightCM || '',
      height_unit: generalHealth?.heightFT || generalHealth?.heightCM ? generalHealth?.heightUnit : '',
      weight: generalHealth?.weight,
      weight_unit: generalHealth?.weight ? generalHealth?.weightUnit : '',

      // Dental Information
      dental_anxiety: dentalInfo?.dentalAnxiety || 0,
      reason_for_scheduling_this_visit: dentalInfo?.reasonForSchedulingThisVisit || '',
      previous_dental_office_name: dentalInfo?.nameOfPreviousOffice,
      last_see_dental_hygienist: dateFormat(dentalInfo?.lastVisitAtDentistOffice),
      date_of_last_dental_x_ray: dateFormat(dentalInfo?.lastTimeYouHadDentalXrayTaken),
      antibiotic_premedication_require_prior_dental_appointments: passTrueFalse(dentalInfo?.doesYourPhysicianRecommendAntibiotic),
      antibiotic_premedication: dentalInfo?.antibioticPremeditation,
      dental_health_average: dentalInfo?.feelDentalHealth,
      dental_info: arrayToString(dentalInfo?.checkIfYouHaveEverHadFollowing),
      appearance_smile_scale_to_10: dentalInfo?.rateAppearanceOfYourSmile,
      wish_correct_appearance_smile: passTrueFalse(dentalInfo?.correctAppearanceOfYourSmile),
      wish_whiter_smile: passTrueFalse(dentalInfo?.hadWhiterSmile),
      snack_3x_day: passTrueFalse(dentalInfo?.doYouSnackMoreThatDay),
      brand_toothpaste: dentalInfo?.toothpasteBrand,
      tooth_extracted_last_3_year: passTrueFalse(dentalInfo?.toothExtractedInLastThreeYear),
      filling_had_in_last_3_year: dentalInfo?.fillingsInLastThreeYear,
      family_history_of_cavities_toothloss: passTrueFalse(dentalInfo?.familyHistoryOfCavities),
      homecare_protective_factors: arrayToString(dentalInfo?.homeCareProtectiveFactors),

      // Sleep Quality

      diagnosed_sleep_apnea_ever: passTrueFalse(sleepQuality?.diagnosedSleepApnea),
      neck_circumference: sleepQuality?.neckCircumferenceMeasured,
      loud_snore: sleepQuality?.snoreLoudly,
      fatigue_daily_bases: passTrueFalse(sleepQuality?.feelDuringDay),
      breathing_stop_sleep: passTrueFalse(sleepQuality?.gaspDuringSleep),
      difficulty_breathing_through_your_nose: passTrueFalse(sleepQuality?.difficultyBreathingNose),
      cpap_machine: passTrueFalse(sleepQuality?.useCPAPMachine),
      oral_appliance_sleep: passTrueFalse(sleepQuality?.useOralAppliance),
      sitting_reading_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[0]),
      watching_tv_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[1]),
      sitting_inactive_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[2]),
      being_passenger_car_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[3]),
      lying_down_afternoon_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[4]),
      sitting_talking_someone_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[5]),
      sitting_quietly_lunch_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[6]),
      stopping_fewminutes_driving_epworth_scale: checkSleep(sleepQuality?.additionalSleep?.map((res: any) => res)[7]),

      // Medical Health History
      physicians_name: medicalHealthHistory?.physicianName,
      physician_phone_number: medicalHealthHistory?.physicianContactNumber,
      last_visit_to_physician: dateFormat(medicalHealthHistory?.dateOfLastVisit),
      serious_illness_or_operation_existance: passTrueFalse(medicalHealthHistory?.seriousIllnessesOrOperations),
      serious_illness_or_operation_name: medicalHealthHistory?.seriousIllnessesOrOperationsInput,
      currently_taking_any_medication: passTrueFalse(medicalHealthHistory?.takingMedicationsOrSupplements),
      medication_list: medicalHealthHistory?.takingMedicationsOrSupplementsInput,
      chest_pain_shortness_breath: passTrueFalse(medicalHealthHistory?.shortnessBreath),
      nursing: passTrueFalse(medicalHealthHistory?.nursing),
      birth_control: passTrueFalse(medicalHealthHistory?.takingBirthControl),
      pregnant: passTrueFalse(medicalHealthHistory?.pregnant),
      medical_conditions: arrayToString(medicalHealthHistory?.medicalConditions),
      medical_conditions_other: medicalHealthHistory?.medicalConditionsOther || '',
      allergies_list: arrayToString(medicalHealthHistory?.checkboxListTwo),
      allergies_list_other: medicalHealthHistory?.checkboxListTwoOtherInput || '',

      // Communication Preferences
      appearance_teeth_scale: communicationPreferences?.appearanceOfYourTeeth,
      loose_tooth_feeling_scale: communicationPreferences?.feelIfYouWereToLoseTooth,
      chew_importance_scale: communicationPreferences?.yourTeethFunctionWell,
      importancy_of_teeth_health_scale: communicationPreferences?.keepYourTeethForRestOfYourLife,
      concerns_appearance_teeth_existance: passTrueFalse(communicationPreferences?.concernsWithAppearanceOfYourTeeth),
      concerns_appearance_teeth_existance_explain: communicationPreferences?.concernsWithAppearanceOfYourTeethReason,
      teeth_sensitivity: passTrueFalse(communicationPreferences?.teethSensitive),
      teeth_sensitivity_answer_yes: communicationPreferences?.teethSensitiveReason,
      describe_yourself_1: communicationPreferences?.describeYourselfMore1,
      describe_yourself_2: communicationPreferences?.describeYourselfMore2,
    } as any

    // Pass insurance in requestBody if user has insurance
    if (letsGetToKnowYouBetter?.doYouHaveDentalInsurance === 'yes') {
      data.insurance = dentistInsuranceInfo.map((res: any, index: number) => {
        return {
          insurance_provider: res.insuranceProvider,
          insurance_group_number: res.insuranceGroup,
          insurance_id: res.insuranceID,
          plan_member_name: res.planMemberName,
          plan_member_dob: res.planMemberDob,
          position: index,
        }
      })
    }
    const patientDetails = {
      sex: patientInformation?.sex === '' ? null : patientInformation?.sex,
      gender: patientInformation?.gender === '' ? null : patientInformation?.gender,
      gender_other: patientInformation?.genderOther,
      race: patientInformation?.race === '' ? null : patientInformation?.race,
      race_other: patientInformation?.raceOther,
    }
    const newPayload = {
      patient_details: patientDetails,
      pre_intake: data,
      update_form: preIntakeData?.completion_status === HeathHistoryCompleted,
      submit_form: payload?.payload?.submit_form,
      patient_id: payload.payload?.patient_id,
      clinic_id: payload.payload?.clinic_id,
      profile_id: payload.payload?.profile_id || null,
    }

    const response = yield API.put(`v1/pre-intake/form`, newPayload)
    yield put(putPreIntakeDataSuccessAction())
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(putPreIntakeDataFailAction())
  }
}

function* downloadPreIntakePdfSaga(payload: any): any {
  try {
    const response = yield API.get(`/v1/pre-intake/form/${payload?.payload?.patient_id}/download/pdf`)
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data)
    }
    yield put(downloadPreIntakePdfSuccessAction(response?.data))
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(downloadPreIntakePdfFailAction())
  }
}

function* getFieldsSaga(payload: any): any {
  const { getFiledData } = yield select((state: any) => state?.preIntake) as any
  try {
    const response = yield API.get(`/page/${payload?.payload?.slug}/field?field_name=${payload?.payload?.fieldName}`)
    const fieldData = response?.data?.detail
    if (payload?.payload?.fieldKey) {
      const updatedFieldData = {
        ...removeRefFromJson(getFiledData),
        [payload?.payload?.fieldKey]: fieldData,
      }
      yield put(getFieldsSuccessAction(updatedFieldData))
    }
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response?.data?.detail)
    }
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(getFieldsFailAction(e))
  }
}

function* preIntakeGetDataWatcher() {
  yield takeEvery(GET_PRE_INTAKE_DATA_REQUEST, getPreIntakeDataSaga)
}

function* preIntakePutDataWatcher() {
  yield takeEvery(PUT_PRE_INTAKE_DATA_REQUEST, putPreIntakeDataSaga)
}

function* downloadIntakePdfWatcher() {
  yield takeEvery(DOWNLOAD_PRE_INTAKE_PDF_REQUEST, downloadPreIntakePdfSaga)
}

function* getFieldsWatcher() {
  yield takeEvery(GET_FIELDS_REQUEST, getFieldsSaga)
}

export default function* preIntakesSaga() {
  yield all([preIntakeGetDataWatcher(), preIntakePutDataWatcher(), downloadIntakePdfWatcher(), getFieldsWatcher()])
}
