import {
  CLINIC_ADMIN_LOGIN_REQUEST,
  CLINIC_ADMIN_LOGIN_REQUEST_FAILURE,
  CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { clinicAdminLoginActions, ClinicAdminLoginInterface } from '../../schema/auth/loginSchema'

const initialState: ClinicAdminLoginInterface = {
  loginLoader: false,
  loginData: null,
  loginError: null,
}

const clinicAdminLoginReducer = (state = initialState, action: clinicAdminLoginActions) => {
  switch (action.type) {
    case CLINIC_ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        loginLoader: true,
      }
    case CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loginLoader: false,
        loginData: action?.payload,
        loginError: null,
      }
    case CLINIC_ADMIN_LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        loginLoader: false,
        loginData: null,
        loginError: action?.payload?.loginError?.meta,
      }
    default:
      return {
        ...state,
      }
  }
}

export default clinicAdminLoginReducer
