import {
  GetOwnerProfileInfoRequest,
  GetOwnerProfileInfoRequestFailure,
  GetOwnerProfileInfoRequestFailurePayload,
  GetOwnerProfileInfoRequestPayload,
  GetOwnerProfileInfoRequestSuccess,
  GetOwnerProfileInfoRequestSuccessPayload,
  PutOwnerProfileInfoRequest,
  PutOwnerProfileInfoRequestFailure,
  PutOwnerProfileInfoRequestFailurePayload,
  PutOwnerProfileInfoRequestPayload,
  PutOwnerProfileInfoRequestSuccess,
  PutOwnerProfileInfoRequestSuccessPayload,
  UpdateOwnerDetailStateRequest,
  UpdateOwnerDetailStateRequestPayload,
} from '../../schema/ownerProfile/ownerProfileSchema'
import {
  EDIT_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
  EDIT_OWNER_PROFILE_INFO_REQUEST,
  EDIT_OWNER_PROFILE_INFO_REQUEST_FAILURE,
  EDIT_OWNER_PROFILE_INFO_REQUEST_SUCCESS,
  GET_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
  GET_OWNER_PROFILE_INFO_REQUEST,
  GET_OWNER_PROFILE_INFO_REQUEST_FAILURE,
  GET_OWNER_PROFILE_INFO_REQUEST_SUCCESS,
  UPDATE_OWNER_DETAILS_STATE,
} from '../actionTypes'

// GET owner profile
export const getOwnerProfileInfoRequest = (payload: GetOwnerProfileInfoRequestPayload): GetOwnerProfileInfoRequest => ({
  type: GET_OWNER_PROFILE_INFO_REQUEST,
  payload,
})
export const getOwnerProfileInfoRequestSuccess = (
  payload: GetOwnerProfileInfoRequestSuccessPayload
): GetOwnerProfileInfoRequestSuccess => ({
  type: GET_OWNER_PROFILE_INFO_REQUEST_SUCCESS,
  payload,
})
export const getOwnerProfileInfoRequestFailure = (
  payload: GetOwnerProfileInfoRequestFailurePayload
): GetOwnerProfileInfoRequestFailure => ({
  type: GET_OWNER_PROFILE_INFO_REQUEST_FAILURE,
  payload,
})
export const getOwnerProfileInfoCacheDataClearRequest = () => ({
  type: GET_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
})

// EDIT owner profile
export const putOwnerProfileInfoRequest = (payload: PutOwnerProfileInfoRequestPayload): PutOwnerProfileInfoRequest => ({
  type: EDIT_OWNER_PROFILE_INFO_REQUEST,
  payload,
})
export const putOwnerProfileInfoRequestSuccess = (
  payload: PutOwnerProfileInfoRequestSuccessPayload
): PutOwnerProfileInfoRequestSuccess => ({
  type: EDIT_OWNER_PROFILE_INFO_REQUEST_SUCCESS,
  payload,
})
export const putOwnerProfileInfoRequestFailure = (
  payload: PutOwnerProfileInfoRequestFailurePayload
): PutOwnerProfileInfoRequestFailure => ({
  type: EDIT_OWNER_PROFILE_INFO_REQUEST_FAILURE,
  payload,
})
export const putOwnerProfileInfoCacheDataClearRequest = () => ({
  type: EDIT_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
})

export const updateOwnerDetailStateRequest = (payload: UpdateOwnerDetailStateRequestPayload): UpdateOwnerDetailStateRequest => ({
  type: UPDATE_OWNER_DETAILS_STATE,
  payload,
})
