import {
  GET_TC_REQUEST,
  GET_TC_REQUEST_FAILURE,
  GET_TC_REQUEST_SUCCESS,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE,
  TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { termsConditionPrivacyPolicyActions, termsConditionPrivacyPolicyState } from '../../schema/auth/termsConditionPrivacyPolicy'

const initialState: termsConditionPrivacyPolicyState = {
  error: null,
  termsConditions: null,
  termsConditionsLoader: false,
  getTCLoader: false,
  getTCData: null,
  getTCError: null,
}

const termsConditionPrivacyPolicyReducer = (state = initialState, action: termsConditionPrivacyPolicyActions) => {
  switch (action.type) {
    case TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        termsConditionsLoader: true,
      }
    case TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_SUCCESS:
      return {
        ...state,
        termsConditionsLoader: false,
        termsConditions: action.payload,
        error: null,
      }
    case TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST_FAILURE:
      return {
        ...state,
        termsConditionsLoader: true,
      }
    case GET_TC_REQUEST:
      return {
        ...state,
        getTCLoader: true,
      }
    case GET_TC_REQUEST_SUCCESS:
      return {
        ...state,
        getTCLoader: false,
        getTCData: action.payload?.getTCData,
        getTCError: null,
      }
    case GET_TC_REQUEST_FAILURE:
      return {
        ...state,
        getTCLoader: false,
        getTCError: action.payload.getTCError,
      }
    default:
      return {
        ...state,
      }
  }
}
export default termsConditionPrivacyPolicyReducer
