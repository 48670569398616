import { Box } from '@mui/material'
import React from 'react'
import BenefitsCompo from '../../components/landingPage/benefits'
import FooterLandingPage from '../../components/landingPage/footer'
import FourDataWithHeader from '../../components/landingPage/fourDataWithHeader'
import HeaderLandingPage from '../../components/landingPage/header'
import PinkPatient from '../../components/landingPage/pinkPatient'
import SignInComponent from '../../components/landingPage/signIn'
import StepperCompo from '../../components/landingPage/steperCompo'
import SubscriptionPage from '../../components/landingPage/subscriptionPage'
import theme from '../../theme'

const LandingPage = () => {
  const palette = theme.palette

  return (
    <Box sx={{ backgroundColor: palette.common.white, height: '100vh' }}>
      <HeaderLandingPage />

      <Box pb={'30px'} sx={{ backgroundColor: palette.common.white, height: `calc(100vh - 90px)`, overflowY: 'scroll' }}>
        <SignInComponent />
        <FourDataWithHeader />
        <PinkPatient />
        <StepperCompo />
        <BenefitsCompo />
        <SubscriptionPage />
        {/* TODO Future Use */}
        {/* <InsightAndResourcePage />
        <FrequentlyQustionPage /> */}
      </Box>
      <FooterLandingPage />
    </Box>
  )
}

export default LandingPage
