import {
  CLINIC_ADMIN_RESET_PASSWORD_REQUEST,
  CLINIC_ADMIN_RESET_PASSWORD_REQUEST_FAILURE,
  CLINIC_ADMIN_RESET_PASSWORD_REQUEST_SUCCESS,
} from '../actionTypes'

import {
  ClinicAdminResetPasswordRequest,
  ClinicAdminResetPasswordRequestFailure,
  ClinicAdminResetPasswordRequestFailurePayload,
  ClinicAdminResetPasswordRequestPayload,
  ClinicAdminResetPasswordRequestSuccess,
  ClinicAdminResetPasswordRequestSuccessPayload,
} from '../../schema/auth/resetPasswordSchema'

export const clinicAdminResetPasswordRequest = (payload: ClinicAdminResetPasswordRequestPayload): ClinicAdminResetPasswordRequest => ({
  type: CLINIC_ADMIN_RESET_PASSWORD_REQUEST,
  payload,
})

export const clinicAdminResetPasswordRequestSuccess = (
  payload: ClinicAdminResetPasswordRequestSuccessPayload
): ClinicAdminResetPasswordRequestSuccess => ({
  type: CLINIC_ADMIN_RESET_PASSWORD_REQUEST_SUCCESS,
  payload,
})

export const clinicAdminResetPasswordRequestFailure = (
  payload: ClinicAdminResetPasswordRequestFailurePayload
): ClinicAdminResetPasswordRequestFailure => ({
  type: CLINIC_ADMIN_RESET_PASSWORD_REQUEST_FAILURE,
  payload,
})
