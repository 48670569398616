import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { VERIFY_USER_EMAIL_REQUEST } from '../../actions/actionTypes'
import { verifyUserEmailRequestFailureAction, verifyUserEmailRequestSuccessAction } from '../../actions/commonFilter/verifyUserEmailAction'

// Verify Email for User already exist
function* verifyUserEmailRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v1/auth/email/verify`, payload?.payload)
    yield put(verifyUserEmailRequestSuccessAction(response?.data?.detail))
    if (response) {
      yield call(payload?.payload?.callback)
    }
  } catch (e: any) {
    // errorToaster(e?.meta?.message)
    if (e?.meta?.code === 404) {
      yield call(payload?.payload?.errorCallback)
    }
    yield put(verifyUserEmailRequestFailureAction(e))
  }
}

function* verifyUserEmailWatch() {
  yield takeEvery(VERIFY_USER_EMAIL_REQUEST, verifyUserEmailRequestSaga)
}

export default function* verifyUserEmailSaga() {
  yield all([verifyUserEmailWatch()])
}
