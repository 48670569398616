import { Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import clinicStepperOne from '../../../assets/images/v2/landingPage/clinicExam/clinicStepperOne.png'
import clinicStepperTwo from '../../../assets/images/v2/landingPage/clinicExam/clinicStepperTwo.png'
import stepperDot from '../../../assets/images/v2/landingPage/stepperDot.png'

import qIconWhite from '../../../assets/images/v2/qIconWhite.png'
import theme from '../../../theme'

const useStyles = makeStyles(() => ({
  headerText: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    display: 'flex',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginLeft: '10px',
  },
  stepperDotStyle: {
    height: '14px',
    width: '14px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.otherDarkPurple,
  },
  stepperSmallDotStyle: {
    height: '11px',
    width: '11px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.otherDarkPurple,
    marginTop: '7px',
  },
  descriptionText: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.black50,
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      lineHeight: '25px',
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    [theme.breakpoints.down(768)]: {
      textAlign: 'start',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  logoItemsBox: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '20px',
    padding: '30px 26px',
    height: '280px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      '& .second-child': {
        color: theme.palette.primary.main,
      },
      border: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('md')]: {
      height: '295px',
    },
    [theme.breakpoints.between(700, 960)]: {
      height: '255px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '250px',
    },
  },
  logoItemsText: {
    fontSize: '18px',
    lineHeight: '25px',
    textTransform: 'uppercase',
    fontWeight: 700,
    margin: '10px 0',
  },
  logoItemsDescription: {
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
  },
  gridMainBox: {
    [theme.breakpoints.down(1100)]: {
      padding: '0 50px',
    },
    [theme.breakpoints.down(450)]: {
      padding: '0',
    },
  },
  gridBoxOne: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down(1100)]: {
      justifyContent: 'center',
    },
  },
  gridBoxTwo: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down(1100)]: {
      justifyContent: 'center',
    },
  },
  stepperOneImage: {
    height: '423px',
    width: '298px',
    position: 'absolute',
    zIndex: `1 !important`,
  },
  stepperTwoImage: {
    height: '556px',
    width: '373px',
    position: 'absolute',
    zIndex: `2 !important`,
    right: '5%',
    bottom: '0',
  },
  stepperThreeImage: {
    height: '266px',
    width: '266px',
    position: 'absolute',
    right: '10%',
  },
  circle: {
    borderRadius: '50%',
    height: '14px',
    width: '14px',
    backgroundColor: theme.palette.primary.otherDarkPurple,
  },
  stepperDescriptionText: {
    // marginLeft: '25px',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 400,
  },
  signUpBtn: {
    height: '36px',
    width: 'fit-content',
    padding: '6px 16px',
    borderRadius: '4px',
    background: theme.palette.v2.primary.main,
    color: theme.palette.common.white,
    textDecoration: 'none',
    textAlign: 'center',
    marginTop: '20px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.v2.primary.main,
      color: theme.palette.common.white,
    },
  },
}))

const StepperCompo = () => {
  const classes = useStyles()
  const palette = theme.palette
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Box className={`d-flex justify-center mt-30`}>
        <Grid
          container={true}
          className={`d-flex`}
          justifyContent="space-between"
          p={'0 30px'}
          maxWidth={1300}
          columnSpacing={{ sm: 0, md: 2 }}
          mt={'20px'}
          minHeight={'600px'}
        >
          <Grid item={true} lg={6} md={6} xs={12}>
            <Box className={'d-flex align-center'}>
              <Box className={classes.stepperDotStyle} />
              <Typography className={classes.headerText}>Create manage & filter appointments</Typography>
            </Box>

            <Typography className={classes.stepperDescriptionText} ml={'25px'}>
              Our user-friendly interface empowers you to schedule appointments with just a few clicks, saving you valuable time. Plus, you
              can easily filter appointments based on specific criteria, including:
            </Typography>

            <Box className={'d-flex align-flex-start'} ml={'25px'} mt={'10px'}>
              <Box>
                <Box className={classes.stepperSmallDotStyle} />
              </Box>
              <Typography className={classes.stepperDescriptionText} ml={'10px'}>
                <b>Roles:</b> Filter appointments by roles such as Assistant, Dentist, or Hygienist. This allows you to focus on specific
                aspects of your practice or delegate tasks efficiently.
              </Typography>
            </Box>

            <Box className={'d-flex align-flex-start'} ml={'25px'} mt={'10px'}>
              <Box>
                <Box className={classes.stepperSmallDotStyle} />
              </Box>
              <Typography className={classes.stepperDescriptionText} ml={'10px'}>
                <b>Status:</b> Sort appointments based on their status, ensuring you stay organised and prioritise accordingly.
              </Typography>
            </Box>

            <Box className={'d-flex align-flex-start'} ml={'25px'} mt={'10px'}>
              <Box>
                <Box className={classes.stepperSmallDotStyle} />
              </Box>
              <Typography className={classes.stepperDescriptionText} ml={'10px'}>
                <b>Exam Type:</b> Filter appointments by exam type, whether it's a Comprehensive Exam (CE) or a Recall Exam (RE). This
                enables you to cater to each patient's specific needs.
              </Typography>
            </Box>

            <Box className={'d-flex align-center'} mt={'20px'}>
              <Box className={classes.stepperDotStyle} />
              <Typography className={classes.headerText}>Convenient search & filtering options</Typography>
            </Box>

            <Typography className={classes.stepperDescriptionText} ml={'25px'}>
              You can search for appointments directly using the search bar, Additionally, our platform provides flexible filtering options
              or Easily narrow down appointments based on specific date ranges or individual days. This helps you efficiently manage your
              schedule and plan your patient care.
            </Typography>

            <Box className={'d-flex align-center'} mt={'20px'}>
              <Box className={classes.stepperDotStyle} />
              <Typography className={classes.headerText}>View comprehensive appointment details</Typography>
            </Box>

            <Typography className={classes.stepperDescriptionText} ml={'25px'}>
              Ensuring you have all the necessary information at your fingertips. You can view details such as the date, time, patient's
              name, exam type, status, wellness score, assigned staff, and actionable options. This holistic view empowers you to make
              informed decisions and deliver personalised care.
            </Typography>
          </Grid>

          <Grid
            item={true}
            lg={6}
            md={6}
            position={'relative'}
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <img src={clinicStepperOne} alt="clinicStepperOne" className={classes.stepperOneImage} />
            <img src={clinicStepperTwo} alt="clinicStepperTwo" className={classes.stepperTwoImage} />
            <img src={stepperDot} alt="stepperDot" className={classes.stepperThreeImage} />
            <Box
              width={'370px'}
              p={'25px'}
              borderRadius={'20px'}
              position={'absolute'}
              bottom={0}
              left={'2%'}
              zIndex={3}
              className={`d-flex justify-content-between`}
              sx={{ backgroundColor: palette.primary.main }}
            >
              <Box>
                <img src={qIconWhite} alt="qIconWhite" />
              </Box>
              <Box className={`d-flex flex-column`} ml={'15px'}>
                <Typography fontSize={'18px'} lineHeight={'25px'} fontWeight={700} color={palette.common.white}>
                  STAY ORGANISED, SAVE TIME & OPTIMISE YOUR WORKFLOW
                </Typography>
                <Button className={classes.signUpBtn} onClick={() => navigate({ pathname: '/sign-up' })}>
                  SIGN UP TODAY
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}

export default StepperCompo
