import {
  CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST,
  CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE,
  CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS,
  IS_MULTIPLE_ACCOUNT_CLINIC_ADMIN_FORGOT_PASSWORD_POPUP,
} from '../../actions/actionTypes'
import { clinicAdminForgotPasswordActions, ClinicAdminForgotPasswordInterface } from '../../schema/auth/forgotPasswordSchema'

const initialState: ClinicAdminForgotPasswordInterface = {
  forgotPasswordLoader: false,
  forgotPasswordData: null,
  forgotPasswordError: '',

  isMultipleAccount: false,
}
const clinicAdminForgotPasswordReducer = (state = initialState, action: clinicAdminForgotPasswordActions) => {
  switch (action.type) {
    case CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordLoader: true,
      }
    case CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        forgotPasswordLoader: false,
        forgotPasswordData: action.payload,
        forgotPasswordError: null,
      }
    case CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        forgotPasswordLoader: false,
        forgotPasswordData: null,
        forgotPasswordError: action.payload.forgotPasswordError,
      }

    case IS_MULTIPLE_ACCOUNT_CLINIC_ADMIN_FORGOT_PASSWORD_POPUP:
      return {
        ...state,
        isMultipleAccount: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
export default clinicAdminForgotPasswordReducer
