import {
  CHANGE_MSA_AGREEMENT,
  CHANGE_PRACTICE_INFORMATION,
  CREATE_CLINIC_REQUEST,
  CREATE_CLINIC_REQUEST_FAILURE,
  CREATE_CLINIC_REQUEST_SUCCESS,
  DELETE_CLINIC_REQUEST,
  DELETE_CLINIC_REQUEST_FAILURE,
  DELETE_CLINIC_REQUEST_SUCCESS,
  DOWNLOAD_CSV_FILE_REQUEST,
  DOWNLOAD_CSV_FILE_REQUEST_FAIL,
  DOWNLOAD_CSV_FILE_REQUEST_SUCCESS,
  GET_ALL_MEMBER_REQUEST,
  GET_ALL_MEMBER_REQUEST_FAILURE,
  GET_ALL_MEMBER_REQUEST_SUCCESS,
  GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  GET_CLINIC_REQUEST,
  GET_CLINIC_REQUEST_FAILURE,
  GET_CLINIC_REQUEST_SUCCESS,
  GET_MSA_AGREEMENT_REQUEST,
  GET_MSA_AGREEMENT_REQUEST_FAIL,
  GET_MSA_AGREEMENT_REQUEST_SUCCESS,
  GET_PMS_SOFTWARE_REQUEST,
  GET_PMS_SOFTWARE_REQUEST_FAILURE,
  GET_PMS_SOFTWARE_REQUEST_SUCCESS,
  GET_PRACTICE_INFORMATION_REQUEST,
  GET_PRACTICE_INFORMATION_REQUEST_FAIL,
  GET_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  GET_PROCEDURE_CODE_CACHE_DATA_CLEAR_REQUEST,
  GET_PROCEDURE_CODE_REQUEST,
  GET_PROCEDURE_CODE_REQUEST_FAILURE,
  GET_PROCEDURE_CODE_REQUEST_SUCCESS,
  GET_SELECTED_PROCEDURE_DATA,
  PUT_MSA_AGREEMENT_REQUEST,
  PUT_MSA_AGREEMENT_REQUEST_FAIL,
  PUT_MSA_AGREEMENT_REQUEST_SUCCESS,
  PUT_PRACTICE_INFORMATION_REQUEST,
  PUT_PRACTICE_INFORMATION_REQUEST_FAIL,
  PUT_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  PUT_PROCEDURE_DATA_REQUEST,
  PUT_PROCEDURE_DATA_REQUEST_FAILURE,
  PUT_PROCEDURE_DATA_REQUEST_SUCCESS,
  UPDATE_CLINIC_REQUEST,
  UPDATE_CLINIC_REQUEST_FAILURE,
  UPDATE_CLINIC_REQUEST_SUCCESS,
  UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_LOCALLY,
  UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_VALIDATIONS,
  UPDATE_CLINIC_STATUS_LOCAL,
  UPDATE_CLINIC_STATUS_REQUEST,
  UPDATE_CLINIC_STATUS_REQUEST_FAILURE,
  UPDATE_CLINIC_STATUS_REQUEST_SUCCESS,
  UPDATE_MSA_AGREEMENT_VALIDATION,
  UPDATE_PRACTICE_INFORMATION_VALIDATION,
  UPDATE_PROCEDURE_DATA_VALIDATIONS,
  UPLOAD_CSV_FILE_REQUEST,
  UPLOAD_CSV_FILE_REQUEST_FAIL,
  UPLOAD_CSV_FILE_REQUEST_SUCCESS,
  VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  VIEW_CLINIC_REQUEST,
  VIEW_CLINIC_REQUEST_FAILURE,
  VIEW_CLINIC_REQUEST_SUCCESS,
} from '../../actions/actionTypes'

import { removedDuplicatesData } from '../../../utils/helper'
import { ClinicInterface, createClinicActions } from '../../schema/clinic/clinicSchema'

const initialState: ClinicInterface = {
  clinicCreateLoader: false,
  clinicCreateData: null,
  clinicCreateError: null,
  getClinicLoader: false,
  getClinicData: [],
  getClinicMeta: null,
  getClinicError: null,
  viewClinicLoader: false,
  viewClinicData: {},
  viewClinicError: null,
  updateClinicLoader: false,
  updateClinicData: null,
  updateClinicError: null,
  deleteClinicLoader: false,
  deleteClinicData: null,
  deleteClinicError: null,
  clinicStatusUpdateLoader: false,
  clinicStatusUpdateData: null,
  clinicStatusUpdateError: null,
  pmsSoftwareLoader: false,
  pmsSoftwareData: null,
  pmsSoftwareError: null,
  allMemberLoader: false,
  allMemberData: null,
  allMemberError: null,
  procedureCodeLoader: false,
  procedureCodeData: null,
  procedureCodeMeta: null,
  procedureCodeError: null,
  selectedProcedureCodeLoader: false,
  selectedProcedureCodeData: null,
  selectedProcedureCodeError: null,
  selectedProcedureData: {
    code: null,
    fees: 0,
    description: null,
    default_code_id: null,
  },
  errors: {
    procedureData: {},
  },
  clinicSpecificErrors: {},
  clinicSpecificProcedureDataLocal: {
    id: null,
    code: null,
    fees: null,
    description: null,
    hygiene_tag: null,
  },
  updateProcedureLoader: false,
  downloadCsvLoader: false,
  downloadCsvData: null,
  downloadCsvError: null,
  uploadCsvLoader: false,
  uploadCsvData: null,
  uploadCsvError: null,
  getPracticeInformationLoader: false,
  getPracticeInformationData: null,
  getPracticeInformationError: null,
  putPracticeInformationLoader: false,
  putPracticeInformationData: null,
  putPracticeInformationError: null,
  practiceInformationLocal: {
    email: null,
    pm_software: null,
    imaging_software: null,
    scheduling_software: null,
    intra_oral_3d_scanner: null,
    practice_days_no: null,
    dentists_no: null,
    assistants_no: null,
    hygienists_no: null,
    npe_patients_no: null,
    recall_patient_no: null,
    exam_duration_new_patient: [],
    is_photos_taken: [],
    other_technologies_used: null,
    extra_comments: null,
    how_preintake_data_collected: [],
    how_often_md_history_updated: [],
  },
  practiceInformationError: {},
  putMsaAgreementLoader: false,
  putMsaAgreementData: null,
  putMsaAgreementError: null,
  msaAgreementLocal: {
    file: null,
    fileValidator: null,
    is_msa_agreed: false,
    customer_legal_name: null,
    address: null,
    customer_representative_name: null,
    phone_number: null,
    customer_representative_email: null,
    clinic_signature_name: null,
    clinic_date_signed: new Date().toLocaleDateString(),
    clinic_title: null,
    postal_code: null,
    attention: null,
    admin_email: null,
    admin_signature_name: null,
    admin_title: null,
  },
  msaAgreementError: {},
  getMsaAgreementLoader: false,
  getMsaAgreementData: null,
  getMsaAgreementError: null,
}
const clinicReducer = (state = initialState, action: createClinicActions) => {
  switch (action.type) {
    case CREATE_CLINIC_REQUEST:
      return {
        ...state,
        clinicCreateLoader: true,
      }
    case CREATE_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        clinicCreateLoader: false,
        clinicCreateData: action.payload,
        clinicCreateError: null,
      }
    case CREATE_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        clinicCreateLoader: false,
        clinicCreateData: null,
        clinicCreateError: action.payload.clinicCreateError,
      }
    case GET_CLINIC_REQUEST:
      return {
        ...state,
        getClinicLoader: true,
      }
    case GET_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        getClinicLoader: false,
        getClinicData: removedDuplicatesData(state.getClinicData, action?.payload?.getClinicData, 'clinic_id'),
        getClinicMeta: action.payload?.getClinicMeta,
        getClinicError: null,
      }
    case UPDATE_CLINIC_STATUS_LOCAL:
      let getClinic = state.getClinicData
      getClinic = getClinic?.map((res: any, i: number) => {
        if (i === action?.payload?.index) {
          return {
            ...res,
            status: res?.status === 'Active' ? 'Inactive' : 'Active',
          }
        }
        return res
      })
      return {
        ...state,
        getClinicData: getClinic,
      }
    case GET_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        getClinicLoader: false,
        getClinicData: null,
        getClinicError: action.payload.getClinicError,
      }
    case GET_CLINIC_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getClinicData: null,
      }
    case VIEW_CLINIC_REQUEST:
      return {
        ...state,
        viewClinicLoader: true,
      }
    case VIEW_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        viewClinicLoader: false,
        viewClinicData: action.payload.viewClinicData,
        viewClinicError: null,
      }
    case VIEW_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        viewClinicLoader: false,
        viewClinicData: null,
        viewClinicError: action.payload.viewClinicError,
      }
    case VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewClinicData: null,
      }
    case UPDATE_CLINIC_REQUEST:
      return {
        ...state,
        updateClinicLoader: true,
      }
    case UPDATE_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        updateClinicLoader: false,
        updateClinicData: action.payload,
        updateClinicError: null,
      }
    case UPDATE_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        updateClinicLoader: false,
        updateClinicData: null,
        updateClinicError: action.payload.updateClinicError,
      }
    case DELETE_CLINIC_REQUEST:
      return {
        ...state,
        deleteClinicLoader: true,
      }
    case DELETE_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        deleteClinicLoader: false,
        deleteClinicError: null,
      }
    case DELETE_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        deleteClinicLoader: false,
        deleteClinicData: null,
        deleteClinicError: action.payload.deleteClinicError,
      }
    case UPDATE_CLINIC_STATUS_REQUEST:
      return {
        ...state,
        clinicStatusUpdateLoader: true,
      }
    case UPDATE_CLINIC_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        clinicStatusUpdateLoader: false,
        clinicStatusUpdateData: action.payload,
        clinicStatusUpdateError: null,
      }
    case UPDATE_CLINIC_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        clinicStatusUpdateLoader: false,
        clinicStatusUpdateData: null,
        clinicStatusUpdateError: action.payload.clinicStatusUpdateError,
      }
    case GET_PMS_SOFTWARE_REQUEST:
      return {
        ...state,
        pmsSoftwareLoader: true,
      }
    case GET_PMS_SOFTWARE_REQUEST_SUCCESS:
      return {
        ...state,
        pmsSoftwareLoader: false,
        pmsSoftwareData: action?.payload?.pmsSoftwareData,
        pmsSoftwareError: null,
      }
    case GET_PMS_SOFTWARE_REQUEST_FAILURE:
      return {
        ...state,
        pmsSoftwareLoader: false,
        pmsSoftwareData: null,
        pmsSoftwareError: action?.payload?.pmsSoftwareError,
      }
    case GET_ALL_MEMBER_REQUEST:
      return {
        ...state,
        allMemberLoader: true,
      }
    case GET_ALL_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        allMemberLoader: false,
        allMemberData: action?.payload?.allMemberData,
        allMemberError: null,
      }
    case GET_ALL_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        allMemberLoader: false,
        allMemberData: null,
        allMemberError: action?.payload?.allMemberError,
      }
    // get procedure code via clinic
    case GET_PROCEDURE_CODE_REQUEST:
      return {
        ...state,
        procedureCodeLoader: true,
      }
    case GET_PROCEDURE_CODE_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        procedureCodeData: null,
      }
    case GET_PROCEDURE_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        procedureCodeLoader: false,
        procedureCodeData: action?.payload?.procedureCodeData,
        procedureCodeMeta: action?.payload?.procedureCodeMeta,
        procedureCodeError: null,
      }
    case GET_PROCEDURE_CODE_REQUEST_FAILURE:
      return {
        ...state,
        procedureCodeLoader: false,
        procedureCodeData: null,
        procedureCodeError: action?.payload?.procedureCodeError,
      }
    case GET_SELECTED_PROCEDURE_DATA:
      return {
        ...state,
        selectedProcedureData: action?.payload?.selectedProcedureData,
      }
    case UPDATE_PROCEDURE_DATA_VALIDATIONS:
      return {
        ...state,
        errors: action.payload,
      }
    case UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_VALIDATIONS:
      return {
        ...state,
        clinicSpecificErrors: action.payload?.clinicSpecificErrors,
      }
    case UPDATE_CLINIC_SPECIFIC_PROCEDURE_DATA_LOCALLY:
      return {
        ...state,
        clinicSpecificProcedureDataLocal: action.payload,
      }
    case PUT_PROCEDURE_DATA_REQUEST:
      return {
        ...state,
        updateProcedureLoader: true,
      }
    case PUT_PROCEDURE_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        updateProcedureLoader: false,
      }
    case PUT_PROCEDURE_DATA_REQUEST_FAILURE:
      return {
        ...state,
        updateProcedureLoader: false,
      }
    case DOWNLOAD_CSV_FILE_REQUEST:
      return {
        ...state,
        downloadCsvLoader: true,
        downloadCsvData: null,
        downloadCsvError: null,
      }
    case DOWNLOAD_CSV_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        downloadCsvLoader: false,
        downloadCsvData: action.payload,
        downloadCsvError: null,
      }
    case DOWNLOAD_CSV_FILE_REQUEST_FAIL:
      return {
        ...state,
        downloadCsvLoader: false,
        downloadCsvData: null,
        downloadCsvError: action.payload,
      }
    // Download CSV
    case UPLOAD_CSV_FILE_REQUEST:
      return {
        ...state,
        uploadCsvLoader: true,
        uploadCsvData: null,
        uploadCsvError: null,
      }
    case UPLOAD_CSV_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        uploadCsvLoader: false,
        uploadCsvData: action.payload,
        uploadCsvError: null,
      }
    case UPLOAD_CSV_FILE_REQUEST_FAIL:
      return {
        ...state,
        uploadCsvLoader: false,
        uploadCsvData: null,
        uploadCsvError: action.payload,
      }
    case GET_PRACTICE_INFORMATION_REQUEST:
      return {
        ...state,
        getPracticeInformationLoader: true,
        getPracticeInformationData: null,
        getPracticeInformationError: null,
      }
    case GET_PRACTICE_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        getPracticeInformationLoader: false,
        getPracticeInformationData: action.payload,
        getPracticeInformationError: null,
      }
    case GET_PRACTICE_INFORMATION_REQUEST_FAIL:
      return {
        ...state,
        getPracticeInformationLoader: false,
        getPracticeInformationData: null,
        getPracticeInformationError: action.payload,
      }
    case PUT_PRACTICE_INFORMATION_REQUEST:
      return {
        ...state,
        putPracticeInformationLoader: true,
        putPracticeInformationData: null,
        putPracticeInformationError: null,
      }
    case PUT_PRACTICE_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        putPracticeInformationLoader: false,
        putPracticeInformationData: action.payload,
        putPracticeInformationError: null,
      }
    case PUT_PRACTICE_INFORMATION_REQUEST_FAIL:
      return {
        ...state,
        putPracticeInformationLoader: false,
        putPracticeInformationData: null,
        putPracticeInformationError: action.payload,
      }
    case CHANGE_PRACTICE_INFORMATION:
      return {
        ...state,
        practiceInformationLocal: action.payload,
      }
    case UPDATE_PRACTICE_INFORMATION_VALIDATION:
      return {
        ...state,
        practiceInformationError: action.payload?.practiceInformationError,
      }
    case PUT_MSA_AGREEMENT_REQUEST:
      return {
        ...state,
        putMsaAgreementLoader: true,
        putMsaAgreementData: null,
        putMsaAgreementError: null,
      }
    case PUT_MSA_AGREEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        putMsaAgreementLoader: false,
        putMsaAgreementData: action.payload,
        putMsaAgreementError: null,
      }
    case PUT_MSA_AGREEMENT_REQUEST_FAIL:
      return {
        ...state,
        putMsaAgreementLoader: false,
        putMsaAgreementData: null,
        putMsaAgreementError: action.payload,
      }
    case CHANGE_MSA_AGREEMENT:
      return {
        ...state,
        msaAgreementLocal: action.payload,
      }
    case UPDATE_MSA_AGREEMENT_VALIDATION:
      return {
        ...state,
        msaAgreementError: action.payload?.msaAgreementError,
      }
    case GET_MSA_AGREEMENT_REQUEST:
      return {
        ...state,
        getMsaAgreementLoader: true,
        getMsaAgreementData: null,
        getMsaAgreementError: null,
      }
    case GET_MSA_AGREEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        getMsaAgreementLoader: false,
        getMsaAgreementData: action.payload,
        getMsaAgreementError: null,
      }
    case GET_MSA_AGREEMENT_REQUEST_FAIL:
      return {
        ...state,
        getMsaAgreementLoader: false,
        getMsaAgreementData: null,
        getMsaAgreementError: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
export default clinicReducer
