import {
  GetClinicFilterListRequest,
  GetClinicFilterListRequestFailure,
  GetClinicFilterListRequestFailurePayload,
  GetClinicFilterListRequestPayload,
  GetClinicFilterListRequestSuccess,
  GetClinicFilterListRequestSuccessPayload,
  SaveCookiesRequest,
  SaveCookiesRequestPayload,
} from '../../schema/commonFilter/clinicFilter'
import {
  GET_CLINIC_FILTER_LIST_REQUEST,
  GET_CLINIC_FILTER_LIST_REQUEST_FAILURE,
  GET_CLINIC_FILTER_LIST_REQUEST_SUCCESS,
  SAVE_COOKIES_REQUEST,
} from '../actionTypes'

// get clinic for the filter list
export const getClinicFilterListRequest = (payload: GetClinicFilterListRequestPayload): GetClinicFilterListRequest => ({
  type: GET_CLINIC_FILTER_LIST_REQUEST,
  payload,
})
export const getClinicFilterListRequestSuccess = (
  payload: GetClinicFilterListRequestSuccessPayload
): GetClinicFilterListRequestSuccess => ({
  type: GET_CLINIC_FILTER_LIST_REQUEST_SUCCESS,
  payload,
})
export const getClinicFilterListRequestFailure = (
  payload: GetClinicFilterListRequestFailurePayload
): GetClinicFilterListRequestFailure => ({
  type: GET_CLINIC_FILTER_LIST_REQUEST_FAILURE,
  payload,
})

// for store the cookies value
export const saveCookiesRequestAction = (payload: SaveCookiesRequestPayload): SaveCookiesRequest => ({
  type: SAVE_COOKIES_REQUEST,
  payload,
})
