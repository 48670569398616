import {
  CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST,
  CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_FAILURE,
  CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { clinicAdminChangePasswordActions, ClinicAdminChangePasswordInterface } from '../../schema/auth/changePasswordSchema'

const initialState: ClinicAdminChangePasswordInterface = {
  changePasswordLoader: false,
  changePasswordData: null,
  changePasswordError: '',
}

const clinicAdminChangePasswordReducer = (state = initialState, action: clinicAdminChangePasswordActions) => {
  switch (action.type) {
    case CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordLoader: true,
      }
    case CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        changePasswordLoader: false,
        changePasswordData: action?.payload,
        changePasswordError: null,
      }
    case CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        changePasswordLoader: false,
        changePasswordData: null,
        changePasswordError: action?.payload?.changePasswordError,
      }
    default:
      return {
        ...state,
      }
  }
}

export default clinicAdminChangePasswordReducer
