import {
  GET_CLINIC_FILTER_LIST_REQUEST,
  GET_CLINIC_FILTER_LIST_REQUEST_FAILURE,
  GET_CLINIC_FILTER_LIST_REQUEST_SUCCESS,
  SAVE_COOKIES_REQUEST,
} from '../../actions/actionTypes'
import { ClinicFilterListInterface, getClinicFilterListAction } from '../../schema/commonFilter/clinicFilter'

const initialState: ClinicFilterListInterface = {
  clinicFilterLoader: false,
  clinicFilterData: null,
  clinicFilterError: null,
  cookiesVal: '',
}
const clinicFilterReducer = (state = initialState, action: getClinicFilterListAction) => {
  switch (action.type) {
    case GET_CLINIC_FILTER_LIST_REQUEST:
      return {
        ...state,
        clinicFilterLoader: true,
      }
    case GET_CLINIC_FILTER_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        clinicFilterLoader: false,
        clinicFilterData: action?.payload?.clinicFilterData,
        clinicFilterError: null,
      }
    case GET_CLINIC_FILTER_LIST_REQUEST_FAILURE:
      return {
        ...state,
        clinicFilterLoader: false,
        clinicFilterData: null,
        clinicFilterError: action?.payload?.clinicFilterError,
      }
    case SAVE_COOKIES_REQUEST:
      return {
        ...state,
        cookiesVal: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export default clinicFilterReducer
