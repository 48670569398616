import { AppBar, Box, Container, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/images/v2/logo.png'
import theme from '../../../theme'

// const navItems = ["TEAM", "SUPPORT", "CONTACT US", "LOG IN"];
const navItems = ['LOG IN']

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'fixed',
    backgroundColor: `${theme.palette.common.white} !important`,
    height: '90px',
    zIndex: 2048,
  },
  headerText: {
    '& .MuiTypography-root': {
      fontSize: '14px',
      lineHeight: '28px',
      fontWeight: '700',
      color: `${theme.palette.common.black} !important`,
      whiteSpace: 'nowrap',
    },
  },
  logoImage: {
    [theme.breakpoints.down('sm')]: {
      width: '80px',
    },
  },
}))

const HeaderLandingPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleClick = (item: any) => {
    if (item === 'LOG IN') {
      navigate({ pathname: `/login` })
    }
  }

  return (
    <AppBar className={classes.appBar} sx={{ zIndex: 2024 }}>
      <Container
        className={`align-center h-100 justify-content-between`}
        sx={{
          display: 'flex !important',
          maxWidth: '100% !important',
          [theme.breakpoints.down(500)]: {
            flexDirection: 'column',
            marginTop: '10px',
          },
        }}
      >
        <img src={logo} alt="logo" className={classes.logoImage} />
        <Box className={'d-flex'} padding={'0px 30px'}>
          {navItems.map((item: string, index: number) => (
            <ListItem key={index} disablePadding={true} onClick={() => handleClick(item)}>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} className={`${classes.headerText}`} />
                {/* TODO FUTURE USE */}
                {/* {item === 'LOG IN' &&
                  <FontAwesomeIcon className='cursor-pointer' fontSize={'10px'} icon={faChevronDown} color={palette.common.black} style={{ marginLeft: '5px' }} />
                } */}
              </ListItemButton>
            </ListItem>
          ))}
        </Box>
      </Container>
    </AppBar>
  )
}

export default HeaderLandingPage
