import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { checkAPIParams, errorToaster, getOwnerData, successToaster } from '../../../utils/helper'
import {
  CREATE_OWNER_REQUEST,
  DELETE_OWNER_REQUEST,
  GET_OWNER_DETAILS_REQUEST,
  GET_OWNER_LIST_REQUEST,
  UPDATE_OWNER_REQUEST,
} from '../../actions/actionTypes'
import {
  changeOwnerDetailsRequestAction,
  createOwnerRequestFailure,
  createOwnerRequestSuccess,
  deleteOwnerRequestFailure,
  deleteOwnerRequestSuccess,
  getOwnerDetailsRequestFailure,
  getOwnerDetailsRequestSuccess,
  getOwnerRequestFailure,
  getOwnerRequestSuccess,
  updateOwnerRequestFailure,
  updateOwnerRequestSuccess,
} from '../../actions/owner/ownerAction'

// Get Owner
function* getOwnerRequestSaga(payload: any): any {
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: 20 },
      { key: 'profile_id', value: getOwnerData()?.id },
      { key: 'clinic_id', value: payload?.payload?.clinic_id },
    ]
    const url = `/v1/owner/list/all${checkAPIParams(params)}`
    const response = yield API.get(url)
    yield put(
      getOwnerRequestSuccess({
        getOwnerListData: response?.data?.detail,
        getOwnerListMeta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(getOwnerRequestFailure({}))
  }
}

// Create Owner
function* createOwnerRequestSaga(payload: any): any {
  const ownerPayload = payload?.payload?.data
  const UpdatedPayload = {
    first_name: ownerPayload?.first_name,
    middle_name: ownerPayload?.middle_name,
    last_name: ownerPayload?.last_name,
    email: ownerPayload?.email,
    mobile_number: ownerPayload?.mobile_number,
    home_number: ownerPayload?.home_number,
    address: ownerPayload?.address,
    postal_code: ownerPayload?.postal_code,
    city: ownerPayload?.city,
    state: ownerPayload?.state,
    country: ownerPayload?.country,
    gender: ownerPayload?.gender,
    date_of_birth: ownerPayload?.date_of_birth,
    clinics: ownerPayload?.clinics,
    communication_preferences: ownerPayload?.communication_preferences,
  }
  try {
    const response = yield API.post(`/v1/owner`, UpdatedPayload)
    if (payload.payload.callback) {
      yield call(payload.payload.callback, response.data)
    }
    yield put(createOwnerRequestSuccess({ ownerCreateData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(createOwnerRequestFailure({ ownerCreateError: e?.meta?.message }))
  }
}

// Create Owner
function* updateOwnerRequestSaga(payload: any): any {
  const ownerPayload = payload?.payload?.data
  const UpdatedPayload = {
    first_name: ownerPayload?.first_name,
    middle_name: ownerPayload?.middle_name,
    last_name: ownerPayload?.last_name,
    email: ownerPayload?.email,
    mobile_number: ownerPayload?.mobile_number,
    home_number: ownerPayload?.home_number,
    address: ownerPayload?.address,
    postal_code: ownerPayload?.postal_code,
    city: ownerPayload?.city,
    state: ownerPayload?.state,
    country: ownerPayload?.country,
    gender: ownerPayload?.gender,
    date_of_birth: ownerPayload?.date_of_birth,
    clinics: ownerPayload?.clinics,
    communication_preferences: ownerPayload?.communication_preferences,
  }
  const owner_id = payload?.payload?.profile ? payload?.payload?.profile?.get('owner_id') : payload?.payload?.data?.id
  const payloadOwner = payload?.payload?.profile ? payload?.payload?.profile : UpdatedPayload

  try {
    const response = yield API.put(`/v1/owner/${owner_id}`, payloadOwner)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(updateOwnerRequestSuccess({ ownerUpdateData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    if (payload?.payload?.errorCallback) {
      payload?.payload?.errorCallback()
    }
    errorToaster(e?.meta?.message)
    yield put(updateOwnerRequestFailure({ ownerUpdateError: e?.meta?.message }))
  }
}

// view owner details
function* getOwnerDetailsRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`/v1/owner/${payload?.payload?.owner_id}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
    yield put(
      changeOwnerDetailsRequestAction({
        ...response?.data?.detail,
        communication_preferences: response?.data?.detail?.communication_preferences ? 'yes' : 'no',
      })
    )
    yield put(getOwnerDetailsRequestSuccess(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      getOwnerDetailsRequestFailure({
        getOwnerDetailsError: e?.meta?.message,
      })
    )
  }
}

// delete owner
function* deleteOwnerRequestSaga(payload: any): any {
  try {
    const url = `/v1/owner/${payload?.payload?.owner_id}/delete`
    const response = yield API.delete(url)
    yield call(payload?.payload?.callback, response?.data)
    successToaster(response?.data?.meta?.message)
    yield put(
      deleteOwnerRequestSuccess({
        deleteOwnerData: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      deleteOwnerRequestFailure({
        deleteOwnerError: e?.meta?.message,
      })
    )
  }
}

function* getOwnerSaga() {
  yield takeEvery(GET_OWNER_LIST_REQUEST, getOwnerRequestSaga)
}

function* createOwnerSaga() {
  yield takeEvery(CREATE_OWNER_REQUEST, createOwnerRequestSaga)
}

function* updateOwnerSaga() {
  yield takeEvery(UPDATE_OWNER_REQUEST, updateOwnerRequestSaga)
}

function* getOwnerDetailsSaga() {
  yield takeEvery(GET_OWNER_DETAILS_REQUEST, getOwnerDetailsRequestSaga)
}

function* deleteOwnerSaga() {
  yield takeEvery(DELETE_OWNER_REQUEST, deleteOwnerRequestSaga)
}

export default function* ownerSaga() {
  yield all([getOwnerSaga(), createOwnerSaga(), updateOwnerSaga(), getOwnerDetailsSaga(), deleteOwnerSaga()])
}
