import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'

import { COMMUNICATIONS_REQUEST } from '../../actions/actionTypes'
import { communicationsRequestFailure, communicationsRequestSuccess } from '../../actions/authActions/communicationAction'

function* communicationRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v1/auth/communication-preferences`, payload.payload)
    yield put(communicationsRequestSuccess(response.data?.detail))
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
  } catch (e: any) {
    yield put(communicationsRequestFailure(e))
  }
}

function* communicationWatch() {
  yield takeEvery(COMMUNICATIONS_REQUEST, communicationRequestSaga)
}

export default function* communicationSaga() {
  yield all([communicationWatch()])
}
